import Icon from '../Icon';
import { colors, H4, TextBody } from '../Common';
import { IButtonActionProps } from './ButtonAction.types';
import * as s from './ButtonAction.styled';

const ButtonAction = ({
    icon,
    title,
    description,
    scheme = 'normal',
    disabled = false,
    ...buttonProps
}: IButtonActionProps) => {
    return (
        <s.ButtonAction type="button" disabled={disabled} scheme={scheme} {...buttonProps}>
            <s.ButtonActionIcon hidden={!icon}>{icon}</s.ButtonActionIcon>
            <s.ButtonActionContent>
                <H4>{title}</H4>
                <TextBody color={colors.text.blackTertiary}>{description}</TextBody>
            </s.ButtonActionContent>
            <s.ButtonActionArrow>chevron_right</s.ButtonActionArrow>
        </s.ButtonAction>
    );
};

ButtonAction.defaultProps = {
    scheme: 'normal',
    disabled: false,
} as const;

ButtonAction.Icon = Icon;

export default ButtonAction;
