import { ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import { Global } from '@emotion/react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isToday from 'dayjs/plugin/isToday';
import calendar from 'dayjs/plugin/calendar';

import colors from './colors';
import { dropdownStyles, fontsStyles, notificationStyles } from './styles';

import 'antd/dist/reset.css';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isToday);
dayjs.extend(calendar);

const UIConfigProvider = ({ children }: { children: ReactNode }) => (
    <ConfigProvider
        componentSize="large"
        theme={{
            token: {
                fontSize: 12,
                lineHeight: 1.6,
                borderRadius: 8,
                fontFamily: 'Poppins',
                colorText: colors.text.blackMain,
                colorPrimary: colors.bg.accent,
                colorBorder: colors.border.main,
                colorTextDisabled: colors.text.blackDisabled,
                colorTextDescription: colors.text.blackTertiary,
                colorBgContainerDisabled: colors.bg.grayDisabled,
                controlOutline: colors.border.focus,
                controlOutlineWidth: 2,
            },
        }}
        getPopupContainer={(target) => target?.parentElement ?? document.body}
    >
        <Global styles={fontsStyles} />
        <Global styles={dropdownStyles} />
        <Global styles={notificationStyles} />
        {children}
    </ConfigProvider>
);

export default UIConfigProvider;
