import styled from '@emotion/styled';

import { colors, TextBody } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const LabelText = styled(TextBody)`
    margin-bottom: 4px;
`;

export const InputIcon = styled(MaterialIcon)`
    display: flex;
    margin: auto;
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;
