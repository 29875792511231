import styled from '@emotion/styled';

import { colors } from '../Common';

export const ErrorStub = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.bg.gray100};
    color: ${colors.bg.gray900};
`;

export const ErrorTitle = styled.h1`
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    text-align: center;
    margin-bottom: 13px;
`;

export const ErrorDescription = styled.p`
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-bottom: 50px;
    color: ${colors.text.blackSecondary};
`;

export const ErrorImage = styled.img`
    height: 172px;
    margin-bottom: 28px;
`;

export const ErrorRectangle = styled.div`
    height: 20px;
    width: 110px;
    margin-bottom: 66px;
    background-color: ${colors.bg.gray900};
`;
