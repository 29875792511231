import { useCallback, useMemo, useState } from 'react';

import FileDocumentImg from '../assets/images/file-document.svg';
import FileImageImg from '../assets/images/file-image.svg';
import { colors, TextBody } from '../Common';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import Dialog from '../Dialog';
import { IFileListItemProps } from './FileList.types';
import * as s from './FileList.styled';

const imageExtensions = ['png', 'jpg', 'jpeg', 'heic', 'heif'];

const FileListItem = ({
    item: { id, name, date, author, deletable },
    onDelete,
    onDownload,
}: IFileListItemProps) => {
    const [isConfirmOpen, setConfirmOpen] = useState(false);

    const extension = name.split('.')[1];

    const fileTypeSrc = useMemo(
        () => (imageExtensions.includes(extension) ? FileImageImg : FileDocumentImg),
        [extension]
    );

    const handleConfirmOpen = useCallback(() => {
        setConfirmOpen(true);
    }, []);

    const handleConfirmClose = useCallback(() => {
        setConfirmOpen(false);
    }, []);

    const handleDelete = useCallback(() => {
        if (onDelete) onDelete(id);
        handleConfirmClose();
    }, [onDelete, id, handleConfirmClose]);

    const handleDownload = useCallback(() => {
        if (onDownload) onDownload(id, name);
    }, [onDownload, id, name]);

    return (
        <s.FileListItem>
            <s.FileName>
                <img src={fileTypeSrc} alt="File type" />
                <TextBody bold="semibold" color={colors.text.blackMain}>
                    {name}
                </TextBody>
            </s.FileName>
            <s.FileMeta>
                <TextBody>{date}</TextBody>
                {author && <TextBody>by</TextBody>}
                {author}
            </s.FileMeta>
            <s.FileActions>
                {onDelete && deletable && (
                    <Tooltip title="Delete file" placement="topRight">
                        <Icon scheme="inline" size="large" onClick={handleConfirmOpen}>
                            delete
                        </Icon>
                    </Tooltip>
                )}
                {onDownload && (
                    <Tooltip title="Download file" placement="topRight">
                        <Icon scheme="inline" size="large" onClick={handleDownload}>
                            download
                        </Icon>
                    </Tooltip>
                )}
            </s.FileActions>
            <Dialog
                type="error"
                okText="Delete"
                cancelText="Cancel"
                title="Delete the file?"
                open={isConfirmOpen}
                onOk={handleDelete}
                onCancel={handleConfirmClose}
                okButtonProps={{ scheme: 'red' }}
            >
                If you delete this file, it will be impossible to recover it.
            </Dialog>
        </s.FileListItem>
    );
};

FileListItem.defaultProps = {};

export default FileListItem;
