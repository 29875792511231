import { ISearchProps } from './Search.types';
import * as s from './Search.styled';
import Input from '../Input';

const Search = (props: ISearchProps) => {
    return <Input {...props} prefix={<s.SearchIcon>search</s.SearchIcon>} />;
};

Search.defaultProps = {
    allowClear: true,
    disabled: false,
} as const;

export default Search;
