import { useCallback, useState } from 'react';

import Avatar from '../../Avatar';
import { colors } from '../../Common';
import { SidebarSubItem } from './SidebarItems';
import { ISidebarProfileProps } from '../Sidebar.types';
import * as s from '../Sidebar.styled';

const SidebarProfile = ({ profile, selectedItem, onSelect }: ISidebarProfileProps) => {
    const [isOpen, setOpen] = useState(false);

    const handleSelect = useCallback(
        (path: string) => {
            onSelect(path);
            setOpen(false);
        },
        [onSelect]
    );

    return (
        <s.SidebarPopover
            open={isOpen}
            trigger="click"
            placement="rightTop"
            onOpenChange={setOpen}
            color={colors.bg.gray900}
            getPopupContainer={(trigger) => trigger}
            content={
                <s.SidebarItems>
                    {profile.items.map((item) => (
                        <SidebarSubItem
                            item={item}
                            key={item.id}
                            onSelect={handleSelect}
                            selected={selectedItem?.id === item.id}
                        />
                    ))}
                </s.SidebarItems>
            }
        >
            <s.SidebarProfileItem>
                <Avatar
                    alt="User avatar"
                    size="medium"
                    src={profile.photoUrl}
                    name={profile.name}
                />
                <s.SidebarDivider />
            </s.SidebarProfileItem>
        </s.SidebarPopover>
    );
};

export default SidebarProfile;
