import Spin from '../Spin';
import * as s from './LoadingStub.styled';
import { ISpinProps } from '../Spin/Spin.types';

const LoadingStub = ({ size }: ISpinProps) => {
    return (
        <s.LoadingStub>
            <Spin size={size} />
        </s.LoadingStub>
    );
};

LoadingStub.defaultProps = {
    size: 'xLarge',
} as const;

export default LoadingStub;
