import { useCallback, MouseEvent } from 'react';

import { TextBody } from '../Common';
import { IButtonAddProps } from './ButtonAdd.types';
import * as s from './ButtonAdd.styled';
import Icon from '../Icon';
import MaterialIcon from '../MaterialIcon';

const ButtonAdd = (props: IButtonAddProps) => {
    const { children, disabled, onClick, ...buttonProps } = props;

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            if (!disabled && onClick) onClick(e);
        },
        [disabled, onClick]
    );

    return (
        <s.ButtonAdd onClick={handleClick} disabled={disabled} {...buttonProps}>
            <s.ButtonAddContent>
                <MaterialIcon>add</MaterialIcon>
                <TextBody bold="medium">{children}</TextBody>
            </s.ButtonAddContent>
        </s.ButtonAdd>
    );
};

ButtonAdd.defaultProps = {
    type: 'button',
    disabled: false,
} as const;

ButtonAdd.Icon = Icon;

export default ButtonAdd;
