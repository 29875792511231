import styled from '@emotion/styled';

import { colors } from '../Common';

export const EmptyResponse = styled.div`
    min-height: 270px;
    position: relative;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.text.blackSecondary};
`;

export const NoDataImg = styled.img`
    margin-bottom: 14px;
`;
