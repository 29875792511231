import MaskedInput, { Mask } from 'react-text-mask';

import Input from '../Input';
import { IPhoneProps } from './Phone.types';

const PhoneMask: Mask = [
    '+',
    '1',
    ' ',
    '(',
    /[2-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];

const Phone = ({ onChange, value, ...inputProps }: IPhoneProps) => {
    return (
        <MaskedInput
            mask={PhoneMask}
            placeholderChar="_"
            value={value}
            onChange={onChange}
            keepCharPositions
            render={(ref, props) => (
                <Input
                    {...inputProps}
                    onChange={props.onChange}
                    value={props.defaultValue}
                    ref={(input) => ref(input?.input as HTMLElement)}
                />
            )}
        />
    );
};

Phone.defaultProps = {
    placeholder: '+1 (___) ___-__-__',
};

export default Phone;
