import cn from 'classnames';
import useFontFaceObserver from 'use-font-face-observer';

import { IMaterialIconProps } from './MaterialIcon.types';
import * as s from './MaterialIcon.styled';

const TIMEOUT = 8000;

const MaterialIcon = ({ children, ...props }: IMaterialIconProps) => {
    const isFontLoaded = useFontFaceObserver([{ family: 'Material Symbols Rounded' }], {
        timeout: TIMEOUT,
    });

    const className = cn(props.className, 'material-symbols-rounded');

    return (
        <s.MaterialIcon {...props} className={className} loaded={isFontLoaded}>
            {children}
        </s.MaterialIcon>
    );
};

export default MaterialIcon;
