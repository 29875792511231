import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

const multipleStyles = css`
    .ant-select-clear {
        margin-top: -11px;
    }

    .ant-select-selector {
        padding-inline-start: 12px !important;
        padding-inline-end: 82px !important;
    }

    .ant-select-selection-item {
        height: 26px !important;
        line-height: 22px !important;
        margin-inline-end: 8px !important;
        padding-inline-end: 8px !important;
        border-radius: 4px !important;
        background-color: ${colors.bg.gray200} !important;
    }

    .ant-select-selection-item-remove {
        display: flex !important;
    }

    .ant-select-selection-item-content {
        margin-inline-end: 10px !important;
    }

    .ant-select-selection-search {
        margin-inline-start: 0 !important;
    }
`;

export const LoadingContainer = styled.div`
    height: 255px;
    display: flex;
    justify-content: center;
`;

export const DropdownContainer = styled.div`
    position: relative;
`;

export const FooterContainer = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 12px;
    border-top: 1px solid ${colors.border.divider};
`;

export const NotFoundContainer = styled.div`
    height: 244px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 4px;
    gap: 8px;
    white-space: pre-line;
`;

export const SelectContainer = styled.div<{ multiple: boolean }>`
    .ant-select {
        width: 100%;
        margin-top: 4px;

        .ant-select-selection-search-mirror {
            position: fixed;
        }

        ${({ multiple }) => multiple && multipleStyles}
    }

    .ant-select-clear {
        width: fit-content;
        height: 18px;
        margin-top: -9px;
    }
`;

export const Option = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: unset;
`;

export const OptionIcon = styled(MaterialIcon)`
    font-weight: 300;
    color: inherit;
`;

export const SuffixIcon = styled(MaterialIcon)`
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;

export const SelectIcon = styled(MaterialIcon)`
    margin: auto;
    font-weight: 300;
    color: ${colors.text.accent};
`;

export const ClearIcon = styled(MaterialIcon)`
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;

export const RemoveIcon = styled(MaterialIcon)`
    font-size: 16px;
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;
