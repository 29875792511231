import { useCallback, useState } from 'react';

import { colors, TextSmall } from '../../Common';
import { SidebarSubItem } from './SidebarItems';
import { ISidebarMoreProps } from '../Sidebar.types';
import * as s from '../Sidebar.styled';

const SidebarMore = ({ items, selectedItem, onSelect }: ISidebarMoreProps) => {
    const [isOpen, setOpen] = useState(false);

    const handleSelect = useCallback(
        (path: string) => {
            onSelect(path);
            setOpen(false);
        },
        [onSelect]
    );

    return (
        <s.SidebarPopover
            open={isOpen}
            trigger="click"
            placement="rightTop"
            onOpenChange={setOpen}
            color={colors.bg.gray900}
            getPopupContainer={(trigger) => trigger}
            content={
                <s.SidebarItems>
                    {items.map((item) => (
                        <SidebarSubItem
                            item={item}
                            key={item.id}
                            onSelect={handleSelect}
                            selected={selectedItem?.id === item.id}
                        />
                    ))}
                </s.SidebarItems>
            }
        >
            <s.SidebarMoreItem>
                <s.SidebarItemIcon>more_horiz</s.SidebarItemIcon>
                <TextSmall>More</TextSmall>
            </s.SidebarMoreItem>
        </s.SidebarPopover>
    );
};

export default SidebarMore;
