import styled from '@emotion/styled';
import { Modal } from 'antd';

import { IDialogProps } from './Dialog.types';
import { colors, TextBody } from '../Common';
import MaterialIcon from '../MaterialIcon';

const dialogColors = new Map<IDialogProps['type'], string>([
    ['info', colors.text.accent],
    ['error', colors.text.error],
    ['warning', colors.text.warning],
    ['success', colors.text.success],
]);

export const Dialog = styled(Modal)`
    .ant-modal-content {
        max-width: 460px;
        border-radius: 0;
        padding: 32px 32px 24px;
    }
`;

export const DialogTitle = styled.header`
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    word-break: break-word;
    gap: 16px;
`;

export const DialogFooter = styled.footer`
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
`;

export const DialogContent = styled(TextBody)`
    margin-left: 44px;
`;

export const TitleIcon = styled(MaterialIcon)<Pick<IDialogProps, 'type'>>`
    font-size: 28px;
    font-weight: 300;
    color: ${({ type }) => dialogColors.get(type)};
`;
