import styled from '@emotion/styled';
import { Modal as AntdModal } from 'antd';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const Modal = styled(AntdModal)`
    width: 100% !important;
    max-width: 572px;

    .ant-modal-content {
        padding: 0;
    }

    .ant-modal-header {
        border-bottom: 1px solid ${colors.border.divider};
        padding: 16px 32px;
    }

    .ant-modal-close {
        inset-inline-end: 32px;
        background-color: transparent !important;
    }
`;

export const ModalFooter = styled.footer`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 32px;
    border-top: 1px solid ${colors.border.divider};
`;

export const ModalContent = styled.div`
    padding: 16px 32px 4px;
`;

export const CloseIcon = styled(MaterialIcon)`
    font-size: 28px;
    font-weight: 300;
`;
