import { ReactElement, useCallback } from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';

import Spin from '../Spin';
import { TextBody, colors } from '../Common';
import { IAutoCompleteProps } from './AutoComplete.types';
import * as s from './AutoComplete.styled';

const selectIcon = <s.SelectIcon>done</s.SelectIcon>;

const clearIcon = <s.ClearIcon>close</s.ClearIcon>;

const AutoComplete = ({
    label,
    options,
    notFoundContent,
    required,
    loading,
    ...autoCompleteProps
}: IAutoCompleteProps) => {
    const dropdownRender = useCallback(
        (menu: ReactElement) => {
            if (!loading) return menu;

            return (
                <s.LoadingContainer>
                    <Spin />
                </s.LoadingContainer>
            );
        },
        [loading]
    );

    return (
        <label>
            <TextBody color={colors.text.blackSecondary}>
                {label} {required && '*'}
            </TextBody>
            <s.AutoCompleteContainer>
                <AntdAutoComplete
                    {...autoCompleteProps}
                    clearIcon={clearIcon}
                    dropdownRender={dropdownRender}
                    menuItemSelectedIcon={selectIcon}
                >
                    {options?.map((option) => (
                        <AntdAutoComplete.Option key={option.value} value={option.value}>
                            <s.Option>{option.label}</s.Option>
                        </AntdAutoComplete.Option>
                    ))}
                </AntdAutoComplete>
            </s.AutoCompleteContainer>
        </label>
    );
};

AutoComplete.defaultProps = {
    disabled: false,
    loading: false,
    allowClear: true,
    required: false,
    filterOption: true,
} as const;

export default AutoComplete;
