import styled from '@emotion/styled';
import { Skeleton as AntdSkeleton } from 'antd';

export const Skeleton = styled(AntdSkeleton)`
    .ant-skeleton-title {
        margin: 0;
        height: 18px !important;
        border-radius: 0 !important;
    }
`;
