import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { TApiConfig, TApiError, TApiErrorHandler } from './Base.model';
import StorageService from '../Storage';

let baseUrl: string | undefined;

let errorHandler: TApiErrorHandler | undefined;

let defaultErrorMessage: string = 'Something went wrong';

function onFulfilledRequest(config: InternalAxiosRequestConfig) {
    const { accessToken } = StorageService;

    if (accessToken) config.headers.set('Authorization', `Bearer ${accessToken}`);

    return config;
}

async function onRejectedResponse(error: TApiError | AxiosError<Blob>) {
    const data = error.response?.data;

    const errorMsg = data instanceof Blob ? JSON.parse(await data.text()).title : data?.title;

    const message = errorMsg || defaultErrorMessage;
    if (errorHandler) errorHandler({ message });
    return Promise.reject(message);
}

function createApi() {
    if (!baseUrl) throw new Error('Base url is not defined');
    const axiosApi = axios.create({ withCredentials: true, baseURL: baseUrl });
    axiosApi.interceptors.request.use(onFulfilledRequest);
    axiosApi.interceptors.response.use((response) => response, onRejectedResponse);
    return axiosApi;
}

class BaseService {
    readonly api: AxiosInstance;

    slug: string = '';

    constructor(slug: string, api?: AxiosInstance) {
        this.slug = slug;
        this.api = api ?? createApi();
    }
}

const setApiConfig = (apiConfig: TApiConfig) => {
    baseUrl = apiConfig.baseUrl;
    errorHandler = apiConfig.errorHandler;
    if (apiConfig.defaultErrorMessage) defaultErrorMessage = apiConfig.defaultErrorMessage;
};

export default BaseService;

export { setApiConfig };
