import { useCallback } from 'react';
import { SegmentedValue } from 'antd/lib/segmented';
import { Upload } from 'antd';

import { IFileListProps, TFileType } from './FileList.types';
import * as s from './FileList.styled';
import Button from '../Button';
import Segmented from '../Segmented';
import FileListItem from './FileListItem';
import Pagination from '../Pagination';
import FileListLoading from './FileListLoading';

const PAGE_SIZE = 12;

const ACCEPT = '.doc, .docs, .txt, .png, .jpg, .jpeg, .heic, .heif, .pdf, .xls, .xlsx';

const fileTypeOptions: { label: string; value: TFileType }[] = [
    {
        label: 'All',
        value: 'ALL',
    },
    {
        label: 'Documents',
        value: 'DOCUMENTS',
    },
    {
        label: 'Images',
        value: 'IMAGES',
    },
];

const FileList = ({
    items = [],
    onFilter,
    onUpload,
    pagination,
    loading = false,
    filterValue = 'ALL',
    ...callbackProps
}: IFileListProps) => {
    const handleFilter = useCallback(
        (value: SegmentedValue) => {
            if (onFilter) onFilter(value as TFileType);
        },
        [onFilter]
    );

    // TODO: implement file upload
    const handleUpload = useCallback(
        (file: File) => {
            if (onUpload) {
                console.log(file);
            }
            return false;
        },
        [onUpload]
    );

    const pageSize = pagination?.pageSize ?? PAGE_SIZE;

    return (
        <s.FileListContainer>
            <s.FileListHeader>
                {onFilter && (
                    <s.FileListFilter>
                        <Segmented
                            block
                            size="middle"
                            value={filterValue}
                            options={fileTypeOptions}
                            onChange={handleFilter}
                        />
                    </s.FileListFilter>
                )}
                {onUpload && (
                    <Upload
                        multiple
                        accept={ACCEPT}
                        showUploadList={false}
                        beforeUpload={handleUpload}
                    >
                        <Button scheme="primary" prefix="add">
                            Upload new
                        </Button>
                    </Upload>
                )}
            </s.FileListHeader>
            {items.length === 0 && !loading && (
                <s.FileListEmpty type="empty" message="There are no attachments yet" />
            )}
            <s.FileList>
                {!loading &&
                    items.map((item) => (
                        <FileListItem key={item.id} item={item} {...callbackProps} />
                    ))}
                {loading && <FileListLoading pageSize={pageSize} />}
            </s.FileList>
            {pagination && (
                <Pagination {...pagination} showSizeChanger={false} pageSize={pageSize} />
            )}
        </s.FileListContainer>
    );
};

FileList.defaultProps = {
    items: [],
    loading: false,
    filterValue: 'ALL',
} as const;

export default FileList;
