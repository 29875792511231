import { ITextAreaProps } from './TextArea.types';
import * as s from './TextArea.styled';
import { colors } from '../Common';

const TextArea = ({ label, required, ...textAreaProps }: ITextAreaProps) => {
    return (
        <label>
            <s.LabelText hidden={!label} color={colors.text.blackSecondary}>
                {label} {required && '*'}
            </s.LabelText>
            <s.TextArea {...textAreaProps} allowClear={false} />
        </label>
    );
};

TextArea.defaultProps = {
    disabled: false,
    showCount: true,
    maxLength: 1000,
    required: false,
    autoSize: { minRows: 2, maxRows: 2 },
} as const;

export default TextArea;
