import styled from '@emotion/styled';

import { colors } from '../Common';
import { IPhotoProps } from './PhotoGallery.types';

const getPhotoBorder = ({ isActive }: IPhotoProps) => {
    return isActive ? `2px solid ${colors.bg.accent}` : 'none';
};

const getPhotoShadow = ({ isActive }: any) => {
    return isActive ? '0px 0px 0px 4px rgba(127, 169, 199, 0.5)' : 'none';
};

export const PhotoGalleryContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const MainPhotoContainer = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const NavItems = styled.section`
    padding: 0 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 40%;
`;

export const Photo = styled.img`
    max-width: 100%;
    height: 528px;
    overflow: hidden;
`;

export const PhotosContainer = styled.section`
    padding: 0 5px;
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    overflow-x: scroll;
    border-top: 1px solid ${colors.border.divider};
`;

export const PhotoPrev = styled.img<IPhotoProps>`
    border: ${getPhotoBorder};
    box-shadow: ${getPhotoShadow};
    border-radius: 8px;
    min-width: 114px;
    width: 114px;
    height: 130px;
    object-fit: cover;
`;
