import styled from '@emotion/styled';
import { Upload } from 'antd';

import { colors, TextBody } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const Dragger = styled(Upload.Dragger)`
    .ant-upload {
        border-width: 2px;
        border-radius: 8px;

        .ant-upload-btn {
            padding: 0;
        }
    }
`;

export const DraggerContent = styled.section`
    height: 126px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    pointer-events: none;
`;

export const DraggerOverContent = styled(DraggerContent)`
    background-color: ${colors.bg.accentHover};
`;

export const DraggerAccent = styled.b`
    font-weight: normal;
    color: ${colors.text.accent};
`;

export const UploadItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
`;

export const UploadItem = styled.div`
    width: 100%;
    padding: 8px 8px 8px 16px;
    background: ${colors.bg.main};
    border: 1px solid ${colors.border.main};
    border-radius: 8px;
`;

export const UploadItemInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

export const UploadItemError = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    margin-left: 40px;
`;

export const FileName = styled(TextBody)`
    min-width: 70%;
    flex: 1;
`;

export const FileSize = styled(TextBody)`
    margin-right: 24px;
`;

export const FileError = styled(TextBody)`
    color: ${colors.text.error};
`;

export const UploadingIcon = styled.div`
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    background-color: ${colors.text.blackDisabled};
`;

export const ErrorIcon = styled(MaterialIcon)`
    font-size: 22px;
    color: ${colors.text.error};
`;

export const SuccessIcon = styled(MaterialIcon)`
    font-size: 22px;
    color: ${colors.text.success};
`;
