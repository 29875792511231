import { ReactNode } from 'react';

import ButtonLink from '../ButtonLink';
import * as s from './Pagination.styled';
import { IPaginationProps } from './Pagination.types';

const Pagination = (props: IPaginationProps) => {
    const itemRender = (page: number, type: string, originalElement: ReactNode) => {
        if (type === 'prev') {
            return <ButtonLink prefix="arrow_back">Previous</ButtonLink>;
        }
        if (type === 'next') {
            return <ButtonLink suffix="arrow_forward">Next</ButtonLink>;
        }
        return originalElement;
    };

    return (
        <s.Pagination itemRender={itemRender} showSizeChanger={false} hideOnSinglePage {...props} />
    );
};

Pagination.defaultProps = {} as const;

export default Pagination;
