import { RuleObject } from 'antd/es/form';

const requiredRule: RuleObject = { required: true, message: 'Required field' };

const emailRule: RuleObject = {
    message: 'Invalid email address',
    pattern: /^[A-Z0-9a-z._%+-]{1,90}@[A-Za-z0-9.-]+\.[A-Za-z]{2,90}$/,
};

const phoneRule: RuleObject = {
    message: 'Invalid phone number',
    pattern: /^\+1 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
};

const zipCodeRule: RuleObject = {
    message: 'Invalid zip code',
    pattern: /^\d{5}([ -]\d{4})?$/,
};

const SSNRule: RuleObject = {
    message: 'Invalid format',
    pattern: /^(?!0{3})(?!6{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
};

const NPIRule: RuleObject = {
    message: 'Invalid NPI number',
    pattern: /^\d{5}\s\d{5}$/,
};

const DEARule: RuleObject = {
    message: 'Invalid DEA number',
    pattern: /^\S{2}\s\d{6}\s\d$/,
};

const TagsRule: RuleObject = {
    message: 'The maximum inputs number is 50',
    validator: (rule, value) => {
        if (value.length > 50) return Promise.reject();
        return Promise.resolve();
    },
};

const durationRule: RuleObject = {
    pattern: /\d/,
    message: 'Invalid Duration',
};

const burnedCaloriesRule: RuleObject = {
    pattern: /\d/,
    message: 'Invalid Burned calories',
};

export const rules = {
    required: requiredRule,
    email: emailRule,
    phone: phoneRule,
    zipCode: zipCodeRule,
    ssn: SSNRule,
    npi: NPIRule,
    dea: DEARule,
    tags: TagsRule,
    duration: durationRule,
    burnedCalories: burnedCaloriesRule,
};
