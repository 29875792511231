import { useKeycloak } from '@react-keycloak/web';

const useAuth = () => {
    const { keycloak, initialized } = useKeycloak();

    return {
        initialized,
        logout: keycloak.logout,
        authenticated: keycloak.authenticated,
    };
};

export default useAuth;
