import { TextBody } from '../Common';
import { IButtonLinkProps } from './ButtonLink.types';
import * as s from './ButtonLink.styled';

const ButtonLink = ({ prefix, suffix, children, ...buttonLinkProps }: IButtonLinkProps) => {
    return (
        <s.ButtonLink {...buttonLinkProps}>
            <s.ButtonLinkIcon hidden={!prefix}>{prefix}</s.ButtonLinkIcon>
            <TextBody>{children}</TextBody>
            <s.ButtonLinkIcon hidden={!suffix}>{suffix}</s.ButtonLinkIcon>
        </s.ButtonLink>
    );
};

ButtonLink.defaultProps = {
    type: 'button',
    disabled: false,
} as const;

export default ButtonLink;
