import styled from '@emotion/styled';

import { IAlertProps } from './Alert.types';
import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

const alertsBackgrounds = new Map<IAlertProps['type'], string>([
    ['info', colors.bg.statusAccent],
    ['error', colors.bg.statusError],
    ['warning', colors.bg.statusWarning],
    ['success', colors.bg.statusSuccess],
]);

const alertsColors = new Map<IAlertProps['type'], string>([
    ['info', colors.text.accent],
    ['error', colors.text.error],
    ['warning', colors.text.warning],
    ['success', colors.text.success],
]);

export const Alert = styled.em<Pick<IAlertProps, 'type'>>`
    width: 100%;
    padding: 9px 16px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    word-break: break-word;
    gap: 10px;
    border-radius: 4px;
    white-space: pre-line;

    background-color: ${({ type }) => alertsBackgrounds.get(type)};
    color: ${({ type }) => alertsColors.get(type)};
`;

export const AlertIcon = styled(MaterialIcon)`
    padding-top: 2px;
`;
