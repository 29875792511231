import { TextBody } from '../Common';
import * as s from './Alert.styled';
import { IAlertProps } from './Alert.types';

const icons = new Map<IAlertProps['type'], string>([
    ['info', 'info'],
    ['error', 'cancel'],
    ['success', 'check_circle'],
    ['warning', 'error'],
]);

const Alert = ({ type, children, ...alertProps }: IAlertProps) => {
    return (
        <s.Alert type={type} {...alertProps}>
            <s.AlertIcon fill>{icons.get(type)}</s.AlertIcon>
            <TextBody bold="medium">{children}</TextBody>
        </s.Alert>
    );
};

Alert.defaultProps = {
    type: 'info',
} as const;

export default Alert;
