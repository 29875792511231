import React from 'react';
import { conformToMask } from 'react-text-mask';

import { masks } from '../constants';

export const formatMsg = (str: string, value: string = '') => {
    const start = str.split('{{')[0];
    const end = str.split('}}')[1];

    return [start, <u>{value}</u>, end];
};

export const formatPhone = (phone: string) => {
    return phone.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
};

export const setMaskPhone = (phone?: string) => {
    return phone && !/\+|-|\(|\)/g.test(`${phone}`)
        ? conformToMask(`${phone}`, masks.phone).conformedValue
        : phone;
};

export const setNpiNumber = (number: string) => {
    return number ? conformToMask(number, masks.npi).conformedValue : number;
};

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
