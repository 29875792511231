import styled from '@emotion/styled';
import { Steps as AntdSteps } from 'antd';

import { colors, TextBody } from '../Common';
import { TStepState } from './Steps.types';

const getStepNumberColor = ({ state }: { state: TStepState }) => {
    switch (state) {
        case 'active':
            return colors.text.whiteMain;
        case 'prev':
            return colors.text.blackSecondary;
        default:
            return colors.text.blackDisabled;
    }
};

const getStepNumberBgColor = ({ state }: { state: TStepState }) => {
    switch (state) {
        case 'active':
            return colors.bg.accent;
        case 'prev':
            return colors.bg.gray400;
        default:
            return colors.border.divider;
    }
};

const getStepTitleColor = ({ state }: { state: TStepState }) => {
    switch (state) {
        case 'active':
            return colors.text.blackMain;
        case 'prev':
            return colors.text.blackSecondary;
        default:
            return colors.text.blackDisabled;
    }
};

export const Steps = styled(AntdSteps)`
    .ant-steps-item-title {
        padding-inline-end: 20px;

        &::after {
            height: 2px;
            background-color: ${colors.border.divider} !important;
        }
    }
`;

export const StepText = styled(TextBody)`
    line-height: unset;
`;

export const StepNumber = styled.div<{ state: TStepState }>`
    width: 32px;
    height: 32px;
    border-radius: 50%;

    background-color: ${getStepNumberBgColor};
    color: ${getStepNumberColor};
`;

export const StepTitle = styled.div<{ state: TStepState }>`
    color: ${getStepTitleColor};
`;
