import { Segmented as AntdSegmented } from 'antd';
import styled from '@emotion/styled';

export const Segmented = styled(AntdSegmented)`
    .ant-segmented-item-label {
        font-size: 14px;
        padding: 4px 16px;
    }

    .ant-segmented-item {
        min-width: 110px;
    }
`;
