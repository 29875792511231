import Keycloak from 'keycloak-js';
import { AuthClientError, AuthClientEvent, AuthClientTokens } from '@react-keycloak/core/lib/types';

import StorageService from 'services/Storage';
import { notification } from 'ui';

const authClient = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL ?? '',
    realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
});

const initOptions = { onLoad: 'login-required', checkLoginIframe: false };

const onTokens = ({ token }: AuthClientTokens) => {
    StorageService.accessToken = token;
};

const onEvent = (eventType: AuthClientEvent, error?: AuthClientError) => {
    if (eventType.includes('Error'))
        notification.error({ message: error?.error || 'Something went wrong' });
};

export default { authClient, initOptions, onTokens, onEvent, autoRefreshToken: true };
