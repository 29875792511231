import { Input as AntdInput, InputRef } from 'antd';
import { forwardRef } from 'react';

import { IInputProps } from './Input.types';
import * as s from './Input.styled';
import { colors } from '../Common';

const passwordIcon = (visible: boolean) => {
    return (
        <div>
            <s.InputIcon>{visible ? 'visibility' : 'visibility_off'}</s.InputIcon>
        </div>
    );
};

const clearIcon = <s.InputIcon>close</s.InputIcon>;

const Input = forwardRef<InputRef, IInputProps>(
    ({ label, allowClear, required, ...inputProps }, ref) => {
        const isPassword = inputProps.type === 'password';

        return (
            <label>
                <s.LabelText color={colors.text.blackSecondary} hidden={!label}>
                    {label} {required && '*'}
                </s.LabelText>
                {isPassword && (
                    <AntdInput.Password ref={ref} {...inputProps} iconRender={passwordIcon} />
                )}
                {!isPassword && (
                    <AntdInput
                        ref={ref}
                        {...inputProps}
                        allowClear={allowClear ? { clearIcon } : false}
                    />
                )}
            </label>
        );
    }
);

Input.defaultProps = {
    allowClear: true,
    disabled: false,
    required: false,
    maxLength: 255,
} as const;

export default Input;
