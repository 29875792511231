import styled from '@emotion/styled';

import MaterialIcon from '../MaterialIcon';
import { colors, H5 } from '../Common';

export const Message = styled(H5)`
    font-weight: 500;
    white-space: pre-line;
    color: ${colors.text.whiteMain};
`;

export const CloseIcon = styled(MaterialIcon)`
    font-size: 24px;
    color: ${colors.text.whiteTertiary};
`;

export const NotificationIcon = styled(MaterialIcon)`
    font-size: 20px;
    margin-top: 2px;
    color: ${colors.text.whiteMain};
`;
