import styled from '@emotion/styled';

import { colors, TextBody } from '../Common';
import Input from '../Input';
import MaterialIcon from '../MaterialIcon';

export const LabelText = styled(TextBody)`
    margin-bottom: 4px;
`;

export const InputContainer = styled(Input)`
    .ant-input-group-addon {
        background-color: ${colors.bg.gray200} !important;
        color: ${colors.text.blackSecondary} !important;

        .ant-select {
            min-width: 95px;
            max-width: 150px;
            width: fit-content;
        }
    }
`;

export const Option = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: unset;
`;

export const SelectIcon = styled(MaterialIcon)`
    margin: auto;
    font-weight: 300;
    color: ${colors.text.accent};
`;
