import styled from '@emotion/styled';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';
import { IButtonActionProps } from './ButtonAction.types';

const getColor = ({ scheme }: Pick<IButtonActionProps, 'scheme'>) => {
    return scheme === 'warning' ? colors.text.error : colors.text.blackMain;
};

const getHoverColor = ({ scheme }: Pick<IButtonActionProps, 'scheme'>) => {
    return scheme === 'warning' ? colors.text.errorDarker : colors.text.accent;
};

export const ButtonAction = styled.button<Pick<IButtonActionProps, 'scheme'>>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 20px 24px;
    border: 1px solid ${colors.bg.main};
    color: ${getColor};
    background-color: ${colors.bg.main};

    border-radius: 16px;
    transition: color ease-out 0.2s, border ease-out 0.2s;

    &:focus {
        cursor: pointer;
        color: ${getHoverColor};
        border: 1px solid ${colors.bg.accent};
        box-shadow: 0 0 0 2px ${colors.border.focus};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover {
        color: ${getHoverColor};
        cursor: pointer;
    }
`;

export const ButtonActionContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    text-align: left;
`;

export const ButtonActionIcon = styled(MaterialIcon)`
    font-size: 44px;
    font-weight: 200;
`;

export const ButtonActionArrow = styled(MaterialIcon)`
    font-size: 24px;
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;
