import { useMemo } from 'react';

import { IStepsProps, TStepState } from './Steps.types';
import * as s from './Steps.styled';

export const getStateOfStep = (current: number, index: number): TStepState => {
    if (current === index) return 'active';
    if (current > index) return 'prev';
    return 'next';
};

const Steps = ({ items, current = 0, ...stepsProps }: IStepsProps) => {
    const formattedItems = useMemo(
        () =>
            items.map(({ title, ...item }, index) => {
                const state = getStateOfStep(current, index);

                return {
                    ...item,
                    icon: (
                        <s.StepNumber state={state}>
                            <s.StepText bold="semibold">{index + 1}</s.StepText>
                        </s.StepNumber>
                    ),
                    title: (
                        <s.StepTitle state={state}>
                            <s.StepText bold="semibold">{title}</s.StepText>
                        </s.StepTitle>
                    ),
                };
            }),
        [items, current]
    );

    return (
        <>
            <s.Steps items={formattedItems} current={current} {...stepsProps} />
            {items[current]?.content}
        </>
    );
};

Steps.defaultProps = {
    current: 0,
};

export default Steps;
