import styled from '@emotion/styled';
import { Input as AntdInput } from 'antd';

import { TextBody } from '../Common';

export const LabelText = styled(TextBody)`
    margin-bottom: 4px;
`;

export const TextArea = styled(AntdInput.TextArea)`
    &::after {
        font-size: 14px;
        line-height: 22px;
    }
`;
