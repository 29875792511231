import { checkPermission } from '../utils';
import usePermissions from './use-permissions';

const useCheckPermission = <Permission>(permission?: Permission) => {
    const permissions = usePermissions<Permission>();

    return permission ? checkPermission<Permission>(permission, permissions) : true;
};

export default useCheckPermission;
