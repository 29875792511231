import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import keycloak from 'keycloak';
import Routing from 'router/Routing';
import queryClient from 'queries/client';
import { setApiConfig } from 'services/Base';
import ErrorBoundary from 'components/ErrorBoundary';
import { notification, ConfigProvider } from 'ui';

import './App.css';

notification.config({ placement: 'bottom', maxCount: 3, duration: 5 });

setApiConfig({
    baseUrl: `${process.env.REACT_APP_API_URL ?? '/'}api`,
    errorHandler: ({ message }) => notification.error({ message, duration: 0 }),
});

const App = () => {
    return (
        <ReactKeycloakProvider {...keycloak}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <QueryClientProvider client={queryClient}>
                    <React.StrictMode>
                        <ConfigProvider>
                            <ErrorBoundary>
                                <Routing />
                            </ErrorBoundary>
                        </ConfigProvider>
                    </React.StrictMode>
                </QueryClientProvider>
            </BrowserRouter>
        </ReactKeycloakProvider>
    );
};

export default App;
