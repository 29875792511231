import { Mask } from 'react-text-mask';

const DEAMask: Mask = [/\S/, /\S/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/];

const NPIMask: Mask = [/\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];

const SSNMask: Mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const ZipMask: Mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const PhoneMask: Mask = [
    '+',
    '1',
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];

const TimeMask: Mask = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];

export const masks = {
    npi: NPIMask,
    dea: DEAMask,
    ssn: SSNMask,
    phone: PhoneMask,
    time: TimeMask,
    zip: ZipMask,
};
