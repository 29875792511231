import { Tooltip as AntdTooltip, TooltipProps } from 'antd';

const Tooltip = (props: TooltipProps) => {
    return <AntdTooltip {...props} />;
};

Tooltip.defaultProps = {
    title: 'prompt text',
    placement: 'top',
} as const;

export default Tooltip;
