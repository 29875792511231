import styled from '@emotion/styled';

import { colors } from '../Common';
import { IRadioGroupProps, IRadioItemProps } from './RadioGroup.types';

const getRadioGroupDirection = ({ direction }: Pick<IRadioGroupProps, 'direction'>) => direction;

export const RadioGroup = styled.fieldset`
    width: 100%;
    display: flex;
    flex-direction: ${getRadioGroupDirection};
    gap: 18px;
`;

const getBorderColor = ({ checked }: Pick<IRadioItemProps, 'checked'>) =>
    checked ? colors.text.blackMain : colors.border.main;
const getBgColor = ({ checked }: Pick<IRadioItemProps, 'checked'>) =>
    checked ? colors.text.blackMain : colors.bg.main;

export const RadioItem = styled.label<Pick<IRadioItemProps, 'checked'>>`
    position: relative;
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 12px 38px;
    border-radius: 8px;
    border: 1px solid ${getBorderColor};
    background: ${colors.bg.main};
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
`;

export const RadioItemWithDescription = styled.label<Pick<IRadioItemProps, 'checked'>>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 12px 38px;
    border-radius: 8px;
    border: 1px solid ${getBorderColor};
    background: ${colors.bg.main};
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
`;

export const CheckMark = styled.span<Pick<IRadioItemProps, 'checked'>>`
    position: absolute;
    top: 12px;
    left: 12px;
    height: 18px;
    width: 18px;
    border: 1px solid ${getBorderColor};
    background-color: ${getBgColor};
    border-radius: 100px;

    &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${colors.bg.main};
        display: ${({ checked }) => (checked ? 'block' : 'none')};
    }
`;
