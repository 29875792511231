import styled from '@emotion/styled';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';
import Spin from '../Spin';
import { IButtonProps } from './Button.types';

const getColor = ({ scheme }: IButtonProps) => {
    return scheme === 'secondary' ? colors.text.blackMain : colors.text.whiteMain;
};

const getDisabledColor = ({ scheme }: IButtonProps) => {
    return scheme === 'secondary' ? colors.text.blackDisabled : colors.text.whiteMain;
};

const getBgColor = ({ scheme }: IButtonProps) => {
    switch (scheme) {
        case 'primary':
            return colors.bg.gray900;
        case 'green':
            return colors.bg.green;
        case 'red':
            return colors.text.error;
        default:
            return 'transparent';
    }
};

const getDisabledBgColor = ({ scheme }: IButtonProps) => {
    return scheme === 'secondary' ? 'transparent' : colors.text.blackDisabled;
};

const getHoverBgColor = ({ scheme }: IButtonProps) => {
    switch (scheme) {
        case 'primary':
            return colors.bg.gray900Darker;
        case 'green':
            return colors.bg.greenDarker;
        case 'red':
            return colors.text.errorDarker;
        default:
            return 'transparent';
    }
};

const getBorderColor = ({ scheme }: IButtonProps) => {
    return scheme === 'secondary' ? colors.border.main : 'transparent';
};

const getHoverBorderColor = ({ scheme }: IButtonProps) => {
    return scheme === 'secondary' ? colors.text.blackMain : 'transparent';
};

const getSpinColor = ({ scheme }: IButtonProps) => {
    return scheme === 'secondary' ? colors.text.blackMain : colors.text.whiteMain;
};

export const Button = styled.button<IButtonProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 8px 14px;
    width: fit-content;
    min-width: 120px;

    color: ${getColor};
    background-color: ${getBgColor};
    border: 1px solid ${getBorderColor};
    border-radius: 8px;
    transition: background-color ease-out 0.2s, border ease-out 0.2s;

    &:disabled {
        background-color: ${getDisabledBgColor};
        color: ${getDisabledColor};
    }

    &:focus {
        cursor: pointer;
        border: 1px solid ${colors.bg.accent};
        box-shadow: 0 0 0 2px ${colors.border.focus};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover {
        cursor: pointer;
        background-color: ${getHoverBgColor};
        border: 1px solid ${getHoverBorderColor};
    }
`;

export const ButtonSpin = styled(Spin)<IButtonProps>`
    color: ${getSpinColor};
`;

export const ButtonIcon = styled(MaterialIcon)`
    display: inherit;
    font-size: 16px;
`;

export const ButtonContent = styled('div', {
    shouldForwardProp: (field) => field !== 'loading',
})<{ loading?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
`;
