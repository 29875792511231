import { lazy } from 'react';

import { Routes, TRoute } from './types';

const routes: TRoute[] = [
    {
        path: Routes.PROFILE,
        Component: lazy(() => import('../pages/Profile')), // TODO: change when task for profile will be exist
        children: [
            {
                path: Routes.PROFILE_ATTACHMENTS,
                Component: lazy(() => import('../pages/Profile/Attachments')),
            },
        ],
    },
    {
        path: Routes.SETTINGS,
        Component: lazy(() => import('../pages/Settings')),
    },
    {
        path: Routes.DIARY_POSTS,
        Component: lazy(() => import('../pages/DiaryPosts')),
        permission: 'view_diary_post',
    },
    {
        path: Routes.DIARY_POST,
        Component: lazy(() => import('../pages/DiaryPost')),
        permission: 'view_diary_post',
    },
    {
        path: Routes.CREATE_DIARY_POST,
        Component: lazy(() => import('../pages/CreateDiaryPost')),
        permission: 'view_diary_post',
    },
    {
        path: Routes.EDIT_DIARY_POST,
        Component: lazy(() => import('../pages/EditDiaryPost')),
        permission: 'view_diary_post',
    },
    {
        path: Routes.LOGS,
        Component: lazy(() => import('../pages/Logs')),
        permission: 'view_log',
    },
    {
        path: Routes.TREATMENT,
        Component: lazy(() => import('../pages/Treatment')),
        // TODO: fix permission
        permission: 'view_log',
    },
];

export const mainRoute = routes[0];

export default routes;
