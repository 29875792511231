import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const Link = styled(RouterLink)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    width: fit-content;

    color: ${colors.text.accent};
    text-decoration: none;
    background-color: transparent;
    border: none;

    &:focus {
        color: ${colors.text.accentDarker};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover {
        color: ${colors.text.accentDarker};
    }

    &[disabled] {
        pointer-events: none;
        color: ${colors.text.blackDisabled};
    }
`;

export const LinkIcon = styled(MaterialIcon)`
    display: inherit;
    font-size: 16px;
    font-weight: 300;
`;
