import styled from '@emotion/styled';
import { DatePicker as AntdDatePicker } from 'antd';

import { colors, TextBody } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const DatePicker = styled(AntdDatePicker)`
    width: 100%;
`;

export const LabelText = styled(TextBody)`
    margin-bottom: 4px;
`;

export const ClearIcon = styled(MaterialIcon)`
    display: flex;
    margin: auto;
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;

export const NavIcon = styled(MaterialIcon)`
    display: flex;
    margin: auto;
    font-size: 24px;
    font-weight: 300;
    color: ${colors.text.accent};
`;
