import { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Sidebar from 'ui/Sidebar';
import { TPermission } from 'services/Permissions';
import { useAuth, useMenuProfile, usePermissions } from 'shared/hooks';
import { checkPermission } from 'shared/utils';
import { Routes } from 'router/types';

import * as s from './BaseLayout.styled';


// TODO: fix Treatment permission
const items = [
    {
        id: 'treatment',
        text: 'Treatment',
        icon: 'medical_services',
        path: Routes.TREATMENT,
        permission: 'view_log',
    },
    {
        id: 'logs',
        text: 'Logbook',
        icon: 'monitoring',
        path: Routes.LOGS,
        permission: 'view_log',
    },
    {
        id: 'diary',
        text: 'Diary',
        icon: 'import_contacts',
        path: Routes.DIARY_POSTS,
        permission: 'view_diary_post',
    },
] as const;

const getProfileItems = (onLogout: () => void) => [
    {
        id: 'profile',
        path: Routes.PROFILE,
        text: 'Profile',
        icon: 'person',
    },
    {
        id: 'settings',
        path: Routes.SETTINGS,
        text: 'Settings',
        icon: 'settings',
    },
    {
        id: 'logout',
        path: '',
        text: 'Logout',
        icon: 'logout',
        onClick: onLogout,
    },
];

type TBaseLayoutProps = {
    children: ReactNode;
};

const BaseLayout = ({ children }: TBaseLayoutProps) => {
    const location = useLocation();

    const navigate = useNavigate();

    const { authenticated, logout } = useAuth();

    const menuProfile = useMenuProfile();

    const permissions = usePermissions<TPermission>();

    const profileItems = useMemo(() => getProfileItems(logout), [logout]);

    const profile = useMemo(
        () => ({
            ...menuProfile,
            items: profileItems,
        }),
        [menuProfile, profileItems]
    );

    const menuItems = useMemo(
        () =>
            items.filter(({ permission }) => checkPermission<TPermission>(permission, permissions)),
        [permissions]
    );

    const selectedItem = useMemo(() => {
        return [...menuItems, ...profileItems].find(({ path }) =>
            location.pathname.startsWith(path)
        );
    }, [location, menuItems, profileItems]);

    return (
        <s.BaseLayout>
            {authenticated && (
                <Sidebar
                    items={menuItems}
                    profile={profile}
                    selectedItem={selectedItem}
                    onSelect={navigate}
                />
            )}
            <s.BaseLayoutContent>{children}</s.BaseLayoutContent>
        </s.BaseLayout>
    );
};

export default BaseLayout;
