import { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { LoadingStub } from 'ui';
import NotFound from 'pages/NotFound';
import { useAuth } from 'shared/hooks';
import BaseLayout from 'layouts/BaseLayout';

import appRoutes, { mainRoute } from './routes';
import { PrivateRoute } from './PrivateRoute';
import { TRoute } from './types';

const generateRoutes = (routes: TRoute[]) => {
    return routes.map(({ Component, path, permission, children }) => (
        <Route
            key={path}
            path={path}
            element={
                <Suspense fallback={<LoadingStub />}>
                    <PrivateRoute permission={permission}>
                        <Component />
                    </PrivateRoute>
                </Suspense>
            }
        >
            {children && generateRoutes(children)}
        </Route>
    ));
};

const Routing = () => {
    const { initialized } = useAuth();

    if (!initialized) return null;

    const routes = generateRoutes(appRoutes);

    return (
        <BaseLayout>
            <Routes>
                {routes}
                <Route path="/" element={<Navigate to={mainRoute.path} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BaseLayout>
    );
};

export default Routing;
