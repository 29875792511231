import styled from '@emotion/styled';
import { Skeleton } from 'antd';

import EmptyResponse from '../EmptyResponse';
import { colors } from '../Common';

export const FileListContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FileListHeader = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const FileListEmpty = styled(EmptyResponse)`
    margin-top: 140px;
    top: 0;
`;

export const FileListFilter = styled.div`
    width: 347px;
`;

export const FileList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 20px 0 32px;
`;

export const FileListItem = styled.li`
    min-height: 86px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px 50px;
    padding: 18px 20px;
    margin: 0;
    border-radius: 16px;
    background-color: ${colors.bg.main};
    color: ${colors.text.blackTertiary};
`;

export const FileLoading = styled(Skeleton)`
    width: fit-content;

    .ant-skeleton-title {
        height: 24px !important;
        margin: 0;
    }
`;

export const FileTypeLoading = styled(Skeleton)`
    width: fit-content;

    .ant-skeleton-title {
        height: 50px !important;
        margin: 0;
    }
`;

export const FileName = styled.div`
    width: 616px;
    display: flex;
    align-items: center;
    gap: 20px;
    overflow: hidden;

    img {
        height: 50px;
        width: 44px;
    }

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const FileMeta = styled.div`
    width: 390px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
`;

export const FileActions = styled.div`
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
`;
