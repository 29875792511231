import { Component, ReactElement } from 'react';

import { ErrorStub } from 'ui';

type TErrorBoundaryProps = { children: ReactElement };

type TErrorBoundaryState = { hasError: boolean; error: string };

const reloadPage = () => {
    document.location.reload();
    return false;
};

class ErrorBoundary extends Component<TErrorBoundaryProps, TErrorBoundaryState> {
    constructor(props: TErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: '' };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        this.setState((prev) => ({ ...prev, error: error.message }));
    }

    render() {
        if (this.state.hasError) {
            return <ErrorStub onClick={reloadPage} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
