import { useCallback, MouseEvent } from 'react';

import { TextBody } from '../Common';
import { IButtonProps } from './Button.types';
import * as s from './Button.styled';
import Icon from '../Icon';

const Button = (props: IButtonProps) => {
    const { scheme, prefix, suffix, children, loading, disabled, onClick, ...buttonProps } = props;

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            if (!disabled && !loading && onClick) onClick(e);
        },
        [disabled, loading, onClick]
    );

    return (
        <s.Button scheme={scheme} onClick={handleClick} disabled={disabled} {...buttonProps}>
            {loading && <s.ButtonSpin scheme={scheme} />}
            <s.ButtonContent loading={loading}>
                <s.ButtonIcon hidden={!prefix}>{prefix}</s.ButtonIcon>
                <TextBody bold="medium">{children}</TextBody>
                <s.ButtonIcon hidden={!suffix}>{suffix}</s.ButtonIcon>
            </s.ButtonContent>
        </s.Button>
    );
};

Button.defaultProps = {
    type: 'button',
    scheme: 'secondary',
    disabled: false,
    loading: false,
} as const;

Button.Icon = Icon;

export default Button;
