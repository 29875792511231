import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';
import { TDatePickerProps } from './DatePicker.types';
import * as s from './DatePicker.styled';

const suffixIcon = <MaterialIcon>calendar_today</MaterialIcon>;

const clearIcon = <s.ClearIcon>close</s.ClearIcon>;

const prevIcon = <s.NavIcon>arrow_back</s.NavIcon>;

const nextIcon = <s.NavIcon>arrow_forward</s.NavIcon>;

const superPrevIcon = <s.NavIcon>keyboard_double_arrow_left</s.NavIcon>;

const superNextIcon = <s.NavIcon>keyboard_double_arrow_right</s.NavIcon>;

const DatePicker = ({ label, required, ...datePickerProps }: TDatePickerProps) => {
    return (
        <label>
            <s.LabelText color={colors.text.blackSecondary} hidden={!label}>
                {label} {required && '*'}
            </s.LabelText>
            <s.DatePicker
                suffixIcon={suffixIcon}
                prevIcon={prevIcon}
                nextIcon={nextIcon}
                clearIcon={clearIcon}
                superPrevIcon={superPrevIcon}
                superNextIcon={superNextIcon}
                {...datePickerProps}
            />
        </label>
    );
};

DatePicker.defaultProps = {
    allowClear: true,
    disabled: false,
    required: false,
    showToday: false,
    format: 'MM/DD/YYYY',
    placeholder: 'MM/DD/YYYY',
} as const;

export default DatePicker;
