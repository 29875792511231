import * as s from './ErrorStub.styled';
import ErrorStubImage from '../assets/images/error-stub.png';
import Button from '../Button';
import { IErrorStubProps } from './ErrorStub.types';

const ErrorStub = ({ onClick, title, description, buttonText, buttonProps }: IErrorStubProps) => {
    return (
        <s.ErrorStub>
            <s.ErrorImage src={ErrorStubImage} />
            <s.ErrorRectangle />
            <s.ErrorTitle>{title}</s.ErrorTitle>
            <s.ErrorDescription>{description}</s.ErrorDescription>
            {buttonText && (
                <Button {...buttonProps} onClick={onClick}>
                    {buttonText}
                </Button>
            )}
        </s.ErrorStub>
    );
};

ErrorStub.defaultProps = {
    title: 'Something went wrong',
    description: (
        <>
            Sorry, we are facing technical issues. <br />
            Please, refresh the page or try again later.
        </>
    ),
    buttonProps: { scheme: 'primary', prefix: 'refresh' },
    buttonText: 'Refresh Page',
} as const;

export default ErrorStub;
