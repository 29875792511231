import { IAvatarProps } from './Avatar.types';
import * as s from './Avatar.styled';

const avatarSizesMap = new Map<IAvatarProps['size'], number>([
    ['small', 30],
    ['medium', 40],
    ['large', 70],
]);

const getInitials = (name: string) => {
    return name
        .split(' ')
        .map((n) => n[0])
        .join('');
};

const Avatar = ({ size, name, loading, ...props }: IAvatarProps) => {
    const initials = getInitials(name);
    const avatarSize = avatarSizesMap.get(size);

    if (loading) return <s.AvatarLoading {...props} active shape="circle" size={avatarSize} />;

    return (
        <s.Avatar {...props} size={avatarSize}>
            {size === 'small' && <s.AvatarUserSmall>{initials}</s.AvatarUserSmall>}
            {size === 'medium' && <s.AvatarUserMedium>{initials}</s.AvatarUserMedium>}
            {size === 'large' && <s.AvatarUserLarge>{initials}</s.AvatarUserLarge>}
        </s.Avatar>
    );
};

Avatar.defaultProps = {
    size: 'large',
};

export default Avatar;
