import { css } from '@emotion/react';

import colors from '../colors';

export const dropdownStyles = css`
    .ant-select-dropdown {
        padding: 6px 0 8px;

        .ant-select-item {
            padding: 9px 12px !important;
            border-radius: 0 !important;
        }

        .ant-select-item-option-content {
            font-size: 14px;
            font-weight: 400;
        }

        .ant-select-item-option-state {
            display: flex;
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: transparent;
            color: ${colors.text.accent};
        }

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            background-color: ${colors.bg.accentHover};
        }
    }
`;
