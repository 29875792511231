import { ChangeEvent, useCallback } from 'react';

import { colors, TextBody } from '../Common';
import { IRadioGroupProps, IRadioItemProps } from './RadioGroup.types';
import * as s from './RadioGroup.styled';

const RadioItem = ({ item, onChange, checked, group }: IRadioItemProps) => {
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked && onChange) onChange(item.value);
        },
        [onChange, item]
    );

    if (item.description)
        return (
            <s.RadioItemWithDescription checked={checked}>
                <>
                    <TextBody bold="semibold" color={colors.text.blackMain}>
                        {item.label}
                    </TextBody>
                    <input checked={checked} onChange={handleChange} name={group} type="radio" />
                    <s.CheckMark checked={checked} />
                </>
                <TextBody color={colors.text.blackMain}>{item.description}</TextBody>
            </s.RadioItemWithDescription>
        );

    return (
        <s.RadioItem checked={checked}>
            <TextBody color={colors.text.blackMain}>{item.label}</TextBody>
            <input checked={checked} onChange={handleChange} name={group} type="radio" />
            <s.CheckMark checked={checked} />
        </s.RadioItem>
    );
};

const RadioGroup = ({ name, items, value, onChange, ...props }: IRadioGroupProps) => {
    return (
        <s.RadioGroup {...props} aria-label={name}>
            {items.map((item) => (
                <RadioItem
                    group={name}
                    item={item}
                    key={item.value}
                    onChange={onChange}
                    checked={item.value === value}
                />
            ))}
        </s.RadioGroup>
    );
};

RadioGroup.defaultProps = {
    items: [],
    direction: 'column',
} as const;

export default RadioGroup;
