import { useCallback, useEffect, useState } from 'react';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib';

import Spin from '../Spin';
import Alert from '../Alert';
import { colors, TextBody, TextSmall } from '../Common';
import { IDraggerProps, IUploadItemProps } from './DraggerPhoto.types';
import * as s from './DraggerPhoto.styled';

const UploadItemPhoto = ({ file, onRemove, setError }: IUploadItemProps) => {
    const handleRemove = useCallback(() => {
        if (onRemove) onRemove(file);
    }, [onRemove, file]);

    const isUploading = file.status === 'uploading';

    useEffect(() => {
        if (file.status === 'error') setError(file.error);
    }, [file, setError]);

    return (
        <s.UploadItem>
            <s.UploadingIcon hidden={!isUploading}>
                <Spin size="large" />
            </s.UploadingIcon>
            <s.PhotoContainer hidden={isUploading}>
                <s.PhotoImage src={file.response?.url || file.url} />
                <s.CancelButton hidden={!file} scheme="red" onClick={handleRemove}>
                    delete
                </s.CancelButton>
            </s.PhotoContainer>
        </s.UploadItem>
    );
};

const DraggerPhoto = ({
    description,
    fileList = [],
    onChange,
    onRemove,
    ...draggerProps
}: IDraggerProps) => {
    const [error, setError] = useState<string>('');
    const [isDragOver, setDrugOver] = useState(false);

    const handleDragEnter = useCallback(() => {
        setDrugOver(true);
    }, []);

    const handleDragEnd = useCallback(() => {
        setDrugOver(false);
    }, []);

    const handleChange = useCallback(
        (event: UploadChangeParam<UploadFile>) => {
            if (onChange) onChange([...event.fileList]);
            setDrugOver(false);
        },
        [onChange]
    );

    const handleRemove = useCallback(
        (file: UploadFile) => {
            const newFileList = fileList.filter(({ uid }) => uid !== file.uid);

            if (onChange) {
                setError('');
                onChange([...newFileList]);
            }
            if (onRemove) onRemove(file);
        },
        [onChange, onRemove, fileList]
    );

    return (
        <div>
            <s.Dragger
                {...draggerProps}
                fileList={fileList}
                onChange={handleChange}
                showUploadList={false}
            >
                <div
                    onDrop={handleDragEnd}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragEnd}
                >
                    {isDragOver && (
                        <s.DraggerOverContent>
                            <TextBody color={colors.text.accent}>Drag photos here</TextBody>
                        </s.DraggerOverContent>
                    )}
                    {!isDragOver && (
                        <s.DraggerContent>
                            <TextBody color={colors.text.blackSecondary}>
                                Drag and drop photos, or <s.DraggerAccent>Browse</s.DraggerAccent>
                            </TextBody>
                            {description && (
                                <TextSmall color={colors.text.blackTertiary}>
                                    {description}
                                </TextSmall>
                            )}
                        </s.DraggerContent>
                    )}
                </div>
            </s.Dragger>
            <s.UploadItems hidden={fileList.length === 0}>
                {fileList.map((file) => {
                    return (
                        <UploadItemPhoto
                            key={file.uid}
                            file={file}
                            onRemove={handleRemove}
                            setError={setError}
                        />
                    );
                })}
            </s.UploadItems>
            {error && (
                <s.UploadError>
                    <Alert type="error">{error}</Alert>
                </s.UploadError>
            )}
        </div>
    );
};

DraggerPhoto.defaultProps = {
    multiple: true,
    maxCount: 10,
    accept: 'image/*, image/HEIC, image/HEIF',
    fileList: [],
};

export default DraggerPhoto;
