import styled from '@emotion/styled';
import { Popover } from 'antd';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';
import Badge from '../assets/images/badge.svg';

export const SIDEBAR_ITEM_HEIGHT = 72;

export const Sidebar = styled.nav`
    width: 112px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0 8px 24px;
    background-color: ${colors.bg.gray900};
`;

export const SidebarLogo = styled.img`
    height: 52px;
    width: 80px;
    padding: 4px 0;
`;

export const SidebarPopover = styled(Popover)`
    .ant-popover-inner {
        width: 158px;
        padding: 8px;
    }

    .ant-popover-arrow:before {
        background-color: ${colors.bg.gray900};
    }
`;

export const SidebarItems = styled.ul`
    width: 100%;
    min-width: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 0;
    margin: 0;
    color: ${colors.text.whiteTertiary};
`;

export const SidebarItem = styled.li`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 16px;
    height: ${SIDEBAR_ITEM_HEIGHT}px;
    transition: background-color ease-out 0.2s;
    cursor: pointer;

    &:hover {
        background-color: ${colors.bg.grayHover};
    }

    &[aria-current='true'] {
        background-color: ${colors.bg.grayHover};
        color: ${colors.text.whiteMain};
    }
`;

export const SidebarMoreItem = styled(SidebarItem)`
    &:hover,
    &.ant-popover-open {
        background-color: ${colors.bg.grayHover};
    }
`;

export const SidebarProfileItem = styled(SidebarItem)`
    margin-bottom: 12px;

    &:hover,
    &.ant-popover-open {
        background-color: ${colors.bg.grayHover};

        & > hr {
            border-color: transparent;
        }
    }
`;

export const SidebarSubItem = styled.li`
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 14px;
    gap: 8px;

    border-radius: 8px;
    transition: background-color ease-out 0.2s;
    cursor: pointer;

    &:hover {
        background-color: ${colors.bg.grayHover};
    }

    &[aria-current='true'] {
        background-color: ${colors.bg.grayHover};
        color: ${colors.text.whiteMain};
    }
`;

export const SidebarItemIcon = styled(MaterialIcon, {
    shouldForwardProp: (field) => field !== 'badge',
})<{ badge?: boolean }>`
    position: relative;
    font-size: 24px;
    font-weight: 300;

    &:before {
        content: url('${Badge}');
        display: ${({ badge }) => (badge ? 'block' : 'none')};
        position: absolute;
        bottom: 32px;
        right: 0;
        width: 8px;
        height: 8px;
    }
`;

export const SidebarSubItemIcon = styled(SidebarItemIcon)`
    font-size: 18px;

    &:before {
        bottom: 22px;
    }
`;

export const SidebarDivider = styled.hr`
    position: absolute;
    bottom: 0;
    width: 56px;
    margin: 0;
    border-color: ${colors.bg.grayHover};

    transition: border-color ease-out 0.2s;
`;
