import styled from '@emotion/styled';
import { Pagination as AntPagination } from 'antd';

import { colors } from '../Common';

export const Pagination = styled(AntPagination)`
    display: flex;
    justify-content: center;
    margin-top: 24px;

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        a {
            color: ${colors.text.blackSecondary} !important;
        }

        &:hover {
            background-color: ${colors.bg.gray200} !important;
            color: ${colors.text.blackSecondary} !important;
        }
    }

    .ant-pagination-disabled {
        display: none !important;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        padding: 5px;
        button {
            color: ${colors.text.blackSecondary} !important;
        }
    }

    .ant-pagination-item-active {
        border: none;
        background-color: ${colors.bg.gray200};

        a {
            color: ${colors.text.blackMain} !important;
        }
    }
`;
