import styled from '@emotion/styled';
import { Tabs as AntTabs } from 'antd';

import { colors } from '../Common';
import { ITabsProps } from './Tabs.types';

const getTopBackgroundColor = ({ color }: ITabsProps) => {
    return color;
};

export const Tabs = styled(AntTabs)<ITabsProps>`
    position: sticky;
    top: -10px;
    z-index: 1;
    .ant-tabs-nav {
        background-color: ${getTopBackgroundColor};
    }

    .ant-tabs-nav-wrap {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .ant-tabs-tab {
        .ant-tabs-tab-btn {
            color: ${colors.text.blackTertiary} !important;
            text-shadow: none;
        }
    }

    .ant-tabs-tab-active {
        border-bottom: 1px solid ${colors.text.blackMain};
        .ant-tabs-tab-btn {
            color: ${colors.text.blackMain} !important;
            font-weight: 400;
            text-shadow: none;
        }
    }

    .ant-tabs-ink-bar {
        background-color: transparent;
    }
`;
