import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { Select as AntdSelect } from 'antd';

import Spin from '../Spin';
import { TextBody, colors } from '../Common';
import NotFoundImage from '../assets/images/not-found.svg';
import { ISelectProps } from './Select.types';
import * as s from './Select.styled';

const suffixIcon = <s.SuffixIcon>expand_more</s.SuffixIcon>;
const selectIcon = <s.SelectIcon>done</s.SelectIcon>;
const clearIcon = <s.ClearIcon>close</s.ClearIcon>;
const removeIcon = <s.RemoveIcon>close</s.RemoveIcon>;
const clearText = <TextBody color={colors.text.accent}>Clean All</TextBody>;

const MAX_LENGTH = 255;

const Select = ({
    label,
    options,
    footer,
    notFoundContent,
    loading = false,
    required = false,
    disabled = false,
    showSearch = true,
    allowClear = true,
    maxLength = MAX_LENGTH,
    optionFilterProp = 'label',
    notFoundMsg = 'No results found',
    ...selectProps
}: ISelectProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const multiple = selectProps.mode === 'multiple' || selectProps.mode === 'tags';

    const clearSuffix = useMemo(() => (multiple ? clearText : clearIcon), [multiple]);

    const dropdownRender = useCallback(
        (menu: ReactElement) => {
            return (
                <s.DropdownContainer>
                    <s.LoadingContainer hidden={!loading}>
                        <Spin />
                    </s.LoadingContainer>
                    {!loading && menu}
                    {footer && <s.FooterContainer>{footer}</s.FooterContainer>}
                </s.DropdownContainer>
            );
        },
        [loading, footer]
    );

    const notFoundRender = useMemo(() => {
        return (
            <s.NotFoundContainer>
                <img src={NotFoundImage} alt="Not Found" />
                {notFoundMsg && (
                    <TextBody color={colors.text.blackTertiary}>{notFoundMsg}</TextBody>
                )}
                {notFoundContent}
            </s.NotFoundContainer>
        );
    }, [notFoundMsg, notFoundContent]);

    useEffect(() => {
        const container = containerRef.current;
        const inputElement = container?.getElementsByTagName('input')[0];
        if (inputElement) inputElement.maxLength = maxLength;
    }, [containerRef, maxLength]);

    return (
        <label>
            <TextBody color={colors.text.blackSecondary}>
                {label} {required && '*'}
            </TextBody>
            <s.SelectContainer ref={containerRef} multiple={multiple}>
                <AntdSelect
                    {...selectProps}
                    loading={loading}
                    disabled={disabled}
                    allowClear={allowClear}
                    showSearch={showSearch}
                    suffixIcon={suffixIcon}
                    clearIcon={clearSuffix}
                    removeIcon={removeIcon}
                    menuItemSelectedIcon={selectIcon}
                    dropdownRender={dropdownRender}
                    notFoundContent={notFoundRender}
                    optionFilterProp={optionFilterProp}
                >
                    {options?.map(
                        ({ label: optionLabel, icon, description, prefix, postfix, ...option }) => (
                            <AntdSelect.Option {...option} key={option.value} label={optionLabel}>
                                <s.Option>
                                    {prefix}
                                    {icon && <s.OptionIcon>{icon}</s.OptionIcon>}
                                    {optionLabel}
                                    {description && (
                                        <TextBody color={colors.text.blackTertiary}>
                                            {description}
                                        </TextBody>
                                    )}
                                    {postfix}
                                </s.Option>
                            </AntdSelect.Option>
                        )
                    )}
                </AntdSelect>
            </s.SelectContainer>
        </label>
    );
};

Select.defaultProps = {
    disabled: false,
    loading: false,
    showSearch: true,
    allowClear: true,
    required: false,
    notFoundMsg: 'No results found',
    optionFilterProp: 'label',
    maxLength: MAX_LENGTH,
} as const;

export default Select;
