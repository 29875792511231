import styled from '@emotion/styled';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const AddReactionContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 34px;
    margin-left: 6px;
    padding: 6px;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: ${colors.bg.gray100};
    }
`;

export const AddReactionIcon = styled(MaterialIcon)`
    font-size: 22px;
    color: ${colors.text.blackTertiary};
`;

export const EmojiContainer = styled.section`
    display: flex;
    gap: 10px;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    font-size: 22px;
`;

export const EmojiButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 34px;
    padding: 6px 8px;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    background-color: inherit;
    &:hover {
        background-color: ${colors.bg.gray100};
    }
`;
