import styled from '@emotion/styled';
import { Pagination as AntPagination } from 'antd';

import { colors } from '../Common';

export const TableContainer = styled.div`
    .ant-table-thead tr .ant-table-cell {
        background-color: ${colors.bg.gray200};
        color: ${colors.text.blackSecondary};
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        align-items: center;

        &:before {
            display: none;
        }
    }

    .ant-table-thead tr {
        .ant-table-cell:first-child {
            padding-left: 20px !important;
        }
    }
    .ant-table-cell-row-hover {
        background-color: ${colors.bg.accentHover} !important;
        border-radius: 0 !important;
    }

    .ant-table-tbody tr {
        height: 86px;
        .ant-table-cell:first-child {
            padding-left: 20px !important;
        }
    }
`;

export const Pagination = styled(AntPagination)`
    display: flex;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 22px;

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        a {
            color: ${colors.text.blackSecondary} !important;
        }

        &:hover {
            background-color: ${colors.bg.gray200} !important;
            color: ${colors.text.blackSecondary} !important;
        }
    }

    .ant-pagination-disabled {
        display: none !important;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        padding: 5px;
        button {
            color: ${colors.text.blackSecondary} !important;
        }
    }

    .ant-pagination-item-active {
        border: none;
        background-color: ${colors.bg.gray200};

        a {
            color: ${colors.text.blackMain} !important;
        }
    }
`;
