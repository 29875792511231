import * as s from './FileList.styled';
import { IFileListLoadingProps } from './FileList.types';

const FileListLoading = ({ pageSize }: IFileListLoadingProps) => {
    return (
        <>
            {Array.from({ length: pageSize }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <s.FileListItem key={index}>
                    <s.FileName>
                        <s.FileTypeLoading active paragraph={false} title={{ width: 44 }} />
                        <s.FileLoading active paragraph={false} title={{ width: 451 }} />
                    </s.FileName>
                    <s.FileMeta>
                        <s.FileLoading active paragraph={false} title={{ width: 309 }} />
                    </s.FileMeta>
                    <s.FileActions>
                        <s.FileLoading active paragraph={false} title={{ width: 24 }} />
                    </s.FileActions>
                </s.FileListItem>
            ))}
        </>
    );
};

FileListLoading.defaultProps = {};

export default FileListLoading;
