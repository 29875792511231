import styled from '@emotion/styled';
import { Upload } from 'antd';

import { colors } from '../Common';
import Icon from '../Icon';

export const Dragger = styled(Upload.Dragger)`
    .ant-upload {
        border-width: 2px;
        border-radius: 8px;

        .ant-upload-btn {
            padding: 0;
        }
    }
`;

export const DraggerContent = styled.section`
    height: 126px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    pointer-events: none;
`;

export const DraggerOverContent = styled(DraggerContent)`
    background-color: ${colors.bg.accentHover};
`;

export const DraggerAccent = styled.b`
    font-weight: normal;
    color: ${colors.text.accent};
`;

export const UploadItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 20px;
`;

export const UploadItem = styled.div`
    width: 114px;
    height: 130px;
    background: ${colors.bg.main};
    border-radius: 8px;
`;

export const PhotoContainer = styled.div`
    width: 114px;
    height: 130px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 8px;

    &:hover > button {
        opacity: 1;
    }
`;

export const PhotoImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
`;

export const CancelButton = styled(Icon)`
    opacity: 0;
    position: absolute;
    height: 36px;
    width: 36px;
    top: -10px;
    right: -14px;

    transition: opacity ease-out 0.2s;
`;

export const UploadingIcon = styled.div`
    height: 130px;
    width: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    background-color: ${colors.bg.gray200};
`;

export const UploadError = styled.section`
    margin-top: 16px;
`;
