import styled from '@emotion/styled';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const LoadingContainer = styled.div`
    height: 254px;
    display: flex;
    justify-content: center;
`;

export const AutoCompleteContainer = styled.span`
    .ant-select {
        display: block;
        margin-top: 4px;
    }

    .ant-select-clear {
        width: fit-content;
        height: 18px;
        margin-top: -9px;
    }
`;

export const Option = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: unset;
`;

export const SelectIcon = styled(MaterialIcon)`
    margin: auto;
    font-weight: 300;
    color: ${colors.text.accent};
`;

export const ClearIcon = styled(MaterialIcon)`
    font-weight: 300;
    color: ${colors.text.blackTertiary};
`;
