import { LoadingStub } from 'ui';
import { TPermission } from 'services/Permissions';
import { useAuth, useCheckPermission } from 'shared/hooks';
import AccessDenied from 'pages/AccessDenied';

type TPrivateRouteProps = {
    children: JSX.Element;
    permission?: TPermission;
};

export function PrivateRoute({ children, permission }: TPrivateRouteProps) {
    const { authenticated } = useAuth();

    const hasPermission = useCheckPermission<TPermission>(permission);

    if (!authenticated) return <LoadingStub />;

    if (!hasPermission) return <AccessDenied />;

    return children;
}
