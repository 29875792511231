import { useMemo } from 'react';
import useMeasure from 'react-use-measure';

import Logo from '../assets/images/logo.svg';
import { SidebarItem, SidebarMore, SidebarProfile } from './units';
import { ISidebarProps } from './Sidebar.types';
import * as s from './Sidebar.styled';

const PADDINGS = s.SIDEBAR_ITEM_HEIGHT * 2 + 130;

const Sidebar = ({ items, profile, selectedItem, onSelect }: ISidebarProps) => {
    const [itemsRef, { height }] = useMeasure();

    const count = Math.max(Math.floor((height - PADDINGS) / s.SIDEBAR_ITEM_HEIGHT), 0);

    const showingItems = useMemo(() => items.slice(0, count), [items, count]);

    const moreItems = useMemo(() => items.slice(count), [items, count]);

    return (
        <s.Sidebar aria-label="Sidebar" ref={itemsRef}>
            <s.SidebarLogo src={Logo} alt="Logo" />
            <s.SidebarItems>
                <SidebarProfile profile={profile} onSelect={onSelect} selectedItem={selectedItem} />
                {showingItems.map((item) => (
                    <SidebarItem
                        key={item.id}
                        item={item}
                        onSelect={onSelect}
                        selected={selectedItem?.id === item.id}
                    />
                ))}
                {moreItems.length > 0 && (
                    <SidebarMore
                        items={moreItems}
                        onSelect={onSelect}
                        selectedItem={selectedItem}
                    />
                )}
            </s.SidebarItems>
        </s.Sidebar>
    );
};

export default Sidebar;
