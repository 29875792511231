import styled from '@emotion/styled';

import { colors } from '../Common';
import { IIconProps } from './Icon.types';
import MaterialIcon from '../MaterialIcon';

const getColor = ({ scheme }: IIconProps) => {
    switch (scheme) {
        case 'primary':
            return colors.text.whiteMain;
        case 'red':
            return colors.text.whiteMain;
        case 'inline':
            return colors.text.blackTertiary;
        default:
            return colors.text.blackMain;
    }
};

const getDisabledColor = ({ scheme }: IIconProps) => {
    return scheme === 'primary' ? colors.text.whiteMain : colors.text.blackDisabled;
};

const getBgColor = ({ scheme }: IIconProps) => {
    switch (scheme) {
        case 'primary':
            return colors.bg.gray900;
        case 'red':
            return colors.bg.red;
        case 'secondary':
            return colors.bg.main;
        default:
            return 'transparent';
    }
};

const getHoverBgColor = ({ scheme }: IIconProps) => {
    switch (scheme) {
        case 'primary':
            return colors.bg.gray900Darker;
        case 'inline':
            return colors.bg.gray100;
        case 'secondary':
            return colors.bg.main;
        case 'red':
            return colors.bg.redDarker;
        default:
            return 'transparent';
    }
};

const getBorderColor = ({ scheme }: IIconProps) => {
    return scheme === 'secondary' ? colors.border.main : 'transparent';
};

const getDisabledBgColor = ({ scheme }: IIconProps) => {
    return scheme === 'primary' ? colors.text.blackDisabled : 'transparent';
};

const getHoverBorderColor = ({ scheme }: IIconProps) => {
    return scheme === 'secondary' ? colors.text.blackMain : 'transparent';
};

const getIconContentSize = ({ size }: IIconProps) => {
    if (size === 'small') return '18px';
    if (size === 'large') return '24px';
    return '20px';
};

const getIconContentWeight = ({ scheme }: IIconProps) => {
    return scheme === 'inline' ? 300 : 400;
};

const getIconSize = ({ size }: IIconProps) => {
    return size === 'small' ? '30px' : '40px';
};

export const Icon = styled.button<IIconProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: ${getIconSize};
    width: ${getIconSize};
    padding: 5px;

    color: ${getColor};
    background-color: ${getBgColor};
    border: 1px solid ${getBorderColor};
    border-radius: 100px;

    &:disabled {
        background-color: ${getDisabledBgColor};
        color: ${getDisabledColor};
    }

    &:focus {
        cursor: pointer;
        background-color: ${getHoverBgColor};
        border: 1px solid ${colors.bg.accent};
        box-shadow: 0 0 0 2px ${colors.border.focus};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover {
        cursor: pointer;
        background-color: ${getHoverBgColor};
        border: 1px solid ${getHoverBorderColor};
    }
`;

export const IconContent = styled(MaterialIcon)<IIconProps>`
    font-size: ${getIconContentSize};
    font-weight: ${getIconContentWeight};
`;
