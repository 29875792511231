import { useCallback, MouseEvent } from 'react';

import { ICheckboxProps } from './Checkbox.types';
import { TextBody } from '../Common';
import * as s from './Checkbox.styled';

const Checkbox = ({ children, ...props }: ICheckboxProps) => {
    const handleMouseDown = useCallback((e: MouseEvent) => {
        e.preventDefault();
    }, []);

    return (
        <s.CheckboxLabel aria-disabled={props.disabled} onMouseDown={handleMouseDown}>
            <s.Checkbox {...props} type="checkbox" />
            <s.Checkmark />
            <TextBody>{children}</TextBody>
        </s.CheckboxLabel>
    );
};

Checkbox.defaultProps = {
    disabled: false,
} as const;

export default Checkbox;
