import { Popover } from 'antd';

import { reactions } from '../Common/reactions';
import Tooltip from '../Tooltip';
import { IEmojiPickerProps, IEmojiProps } from './EmojiPicker.types';
import * as s from './EmojiPicker.styled';

export const Emoji = ({ label }: IEmojiProps) => {
    const currentEmoji = reactions.find((emoji: IEmojiProps) => emoji.label === label);
    return (
        <span role="img" aria-label={label} aria-hidden={label ? 'false' : 'true'}>
            {currentEmoji?.symbol}
        </span>
    );
};

const content = (onClick: (reaction: string) => void) => (
    <s.EmojiContainer>
        {reactions.map((react: IEmojiProps) => (
            <s.EmojiButton onClick={() => onClick(react.label)} key={react.label}>
                <Emoji label={react.label} />
            </s.EmojiButton>
        ))}
    </s.EmojiContainer>
);

export const EmojiPicker = ({ onClick }: IEmojiPickerProps) => {
    return (
        <Popover placement="bottomRight" content={content(onClick)} trigger="click">
            <Tooltip title="Add reaction">
                <s.AddReactionContainer>
                    <s.AddReactionIcon fill>add_reaction</s.AddReactionIcon>
                </s.AddReactionContainer>
            </Tooltip>
        </Popover>
    );
};
