import styled from '@emotion/styled';

import { ISpinProps } from './Spin.types';

const spinSizeMap = new Map<ISpinProps['size'], string>([
    ['small', '4px'],
    ['medium', '6px'],
    ['large', '9px'],
    ['xLarge', '18px'],
]);

const spinShiftMap = new Map<ISpinProps['size'], string>([
    ['small', '-6px'],
    ['medium', '-9px'],
    ['large', '-14px'],
    ['xLarge', '-27px'],
]);

export const Spin = styled.span<ISpinProps>`
    --size: ${({ size }) => spinSizeMap.get(size)};
    --shift: ${({ size }) => spinShiftMap.get(size)};

    position: absolute;
    top: 50%;
    width: var(--size);
    height: var(--size);
    margin: var(--shift) auto 0 auto;
    border-radius: 50%;
    will-change: transform;
    color: ${({ color }) => color};
    animation: loader-animation 0.9s ease alternate infinite;
    animation-delay: 0.36s;

    &:before {
        content: '';
        position: absolute;
        left: var(--shift);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        animation: loader-animation 0.9s ease alternate infinite;
        animation-delay: 0.18s;
    }

    &:after {
        content: '';
        position: absolute;
        right: var(--shift);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        animation: loader-animation 0.9s ease alternate infinite;
        animation-delay: 0.54s;
    }

    @keyframes loader-animation {
        0% {
            box-shadow: 0 var(--size) 0 calc(-1 * var(--size));
        }

        100% {
            box-shadow: 0 var(--size) 0;
        }
    }
`;
