import { RefObject, useCallback } from 'react';

const useScroll = () => {
    return useCallback((ref: RefObject<HTMLDivElement>) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
        });
    }, []);
};

export default useScroll;
