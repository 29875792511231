import * as s from './Spin.styled';
import { ISpinProps } from './Spin.types';
import { colors } from '../Common';

const Spin = (props: ISpinProps) => {
    return <s.Spin {...props} />;
};

Spin.defaultProps = {
    color: colors.bg.accent,
    size: 'medium',
} as const;

export default Spin;
