import * as s from './Icon.styled';
import { IIconProps } from './Icon.types';

const Icon = ({ children, ...props }: IIconProps) => {
    return (
        <s.Icon {...props}>
            <s.IconContent scheme={props.scheme} size={props.size}>
                {children}
            </s.IconContent>
        </s.Icon>
    );
};

Icon.defaultProps = {
    type: 'button',
    scheme: 'secondary',
    size: 'medium',
    disabled: false,
} as const;

export default Icon;
