import styled from '@emotion/styled';

import { colors } from '../Common';

export const Card = styled.div`
    width: 100%;
    max-width: 812px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 14px 0 20px;
    border-radius: 16px;
    background: ${colors.bg.main};
`;

export const CardItem = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px 34px 0 34px;
    color: ${colors.text.blackMain};
`;

export const CardItemHeader = styled.header`
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const CardItemExtra = styled.div`
    margin-right: -12px;
`;
