import { useEffect, useMemo, useState } from 'react';

import { H3 } from '../Common';
import Button from '../Button';
import { IDialogProps } from './Dialog.types';
import * as s from './Dialog.styled';

const icons = new Map<IDialogProps['type'], string>([
    ['info', 'info'],
    ['error', 'cancel'],
    ['success', 'check_circle'],
    ['warning', 'error'],
]);

const Dialog = ({
    type,
    open,
    onOk,
    onCancel,
    title,
    children,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
}: IDialogProps) => {
    const [isModalOpen, setModalOpen] = useState<boolean>();

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    const footerComponent = useMemo(() => {
        return (
            <s.DialogFooter>
                <Button onClick={onCancel} {...cancelButtonProps}>
                    {cancelText}
                </Button>
                <Button scheme="primary" onClick={onOk} {...okButtonProps}>
                    {okText}
                </Button>
            </s.DialogFooter>
        );
    }, [cancelText, okText, okButtonProps, cancelButtonProps, onCancel, onOk]);

    const titleComponent = useMemo(() => {
        return (
            <s.DialogTitle>
                <s.TitleIcon type={type}>{icons.get(type)}</s.TitleIcon>
                <H3 bold="medium">{title}</H3>
            </s.DialogTitle>
        );
    }, [title, type]);

    return (
        <s.Dialog
            centered
            closable={false}
            open={isModalOpen}
            title={titleComponent}
            footer={footerComponent}
        >
            <s.DialogContent>{children}</s.DialogContent>
        </s.Dialog>
    );
};

Dialog.defaultProps = {
    okText: 'OK',
    cancelText: 'not OK',
    type: 'info',
    open: false,
} as const;

export default Dialog;
