const colors = {
    bg: {
        main: '#FFFFFF',
        mainHover: 'rgba(255, 255, 255, 0.5)',
        accent: '#7FA9C7',
        accentHover: '#EDF3F7',
        gray100: '#F6F6F1',
        gray200: '#ECECDA',
        gray400: '#DCDCCB',
        gray600: '#A9AA9E',
        gray900: '#25282B',
        gray900Darker: '#131416',
        grayHover: 'rgba(255, 255, 255, 0.12)',
        grayDisabled: '#F5F5F4',
        green: '#5AB300',
        red: '#F63D3D',
        redDarker: '#C82424',
        greenDarker: '#458A00',
        statusError: '#FEF0F0',
        statusWarning: '#FFF8F0',
        statusSuccess: '#F8FFF0',
        statusAccent: '#F5F9FF',
    },
    border: {
        main: '#D5D5D3',
        divider: '#ECECE9',
        focus: 'rgba(127, 169, 199, 0.5)',
        errorLighter: 'rgba(247, 61, 61, 0.2)',
        successLighter: 'rgba(90, 179, 0, 0.2)',
    },
    text: {
        blackMain: '#25282B',
        blackSecondary: '#64645B',
        blackTertiary: '#9A9A90',
        blackDisabled: '#D2D2CF',
        whiteMain: '#FFFFFF',
        whiteSecondary: 'rgba(255, 255, 255, 0.85)',
        whiteTertiary: 'rgba(255, 255, 255, 0.65)',
        whiteDisabled: 'rgba(255, 255, 255, 0.3)',
        error: '#F73D3D',
        errorDarker: '#C82424',
        warning: '#FD8210',
        warningDarker: '#E36E02',
        success: '#65C900',
        successDarker: '#4F9E00',
        accent: '#0568DC',
        accentDarker: '#03428C',
    },
    additional: {
        violet: '#9B82E3',
        aquamarine: '#5FBAA4',
        pink: '#E37FA9',
    },
};

export default colors;
