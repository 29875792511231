import styled from '@emotion/styled';

import { colors } from 'ui';

export const NotFound = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 110px;
    color: ${colors.bg.gray900};
`;

export const NotFoundImg = styled.img`
    height: 345px;
    margin-bottom: 55px;
`;

export const NotFoundTitle = styled.h1`
    font-size: 60px;
    font-weight: 500;
    line-height: 90px;
    text-align: center;
    margin-bottom: 13px;
`;

export const NotFoundDescription = styled.p`
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-bottom: 50px;
    color: ${colors.text.blackSecondary};
`;
