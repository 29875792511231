import React from 'react';

import { H4 } from '../Common';
import { ICardItemProps, ICardProps } from './Card.types';
import * as s from './Card.styled';

const Card = ({ children, ...props }: ICardProps) => {
    return <s.Card {...props}>{children}</s.Card>;
};

const CardItem = ({ title, children, extra, ...props }: ICardItemProps) => {
    return (
        <s.CardItem {...props}>
            <s.CardItemHeader>
                <H4>{title}</H4>
                {extra && <s.CardItemExtra>{extra}</s.CardItemExtra>}
            </s.CardItemHeader>
            <div>{children}</div>
        </s.CardItem>
    );
};

Card.Item = CardItem;

export default Card;
