import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { colors } from '../Common';
import { TCheckboxGroupLayout } from './CheckboxGroup.types';

export const CheckboxGroup = styled.section`
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding-top: 6px;
    color: ${colors.text.blackMain};
`;

export const CheckboxChildren = styled.div`
    margin: 8px 24px 0;
`;

const VerticalGroupStyles = css`
    flex-direction: column;
    gap: 18px;
`;

const HorizontalGroupStyles = css`
    flex-direction: row;
    gap: 20px;
`;

export const CheckboxGroupItems = styled.div<{ layout: TCheckboxGroupLayout }>`
    display: flex;
    ${({ layout }) => (layout === 'horizontal' ? HorizontalGroupStyles : VerticalGroupStyles)};
`;
