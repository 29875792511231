import { ReactNode, useMemo } from 'react';
import { Skeleton as AntSkeleton, Table as AntTable } from 'antd';

import { ITableProps } from './Table.types';
import * as s from './Table.styled';
import ButtonLink from '../ButtonLink';

const INIT_COLUMNS_LENGTH = 5;
const INIT_ROWS_LENGTH = 8;

const stub = [...Array(INIT_ROWS_LENGTH)].map((_, i) => ({ id: i, title: '' }));

const Table = <TData extends object>({
    pagination,
    columns,
    dataSource,
    loading,
    ...props
}: ITableProps<TData>) => {
    const itemRender = (page: number, type: string, originalElement: ReactNode) => {
        if (type === 'prev') {
            return <ButtonLink prefix="arrow_back">Previous</ButtonLink>;
        }
        if (type === 'next') {
            return <ButtonLink suffix="arrow_forward">Next</ButtonLink>;
        }
        return originalElement;
    };

    const loadColumns = useMemo(() => {
        const loadCol = [];
        for (let i = 0; i < (columns?.length || INIT_COLUMNS_LENGTH); i += 1) {
            loadCol.push({
                id: i,
                title: '',
                render: () => <AntSkeleton active paragraph={{ rows: 0, width: '100%' }} />,
            });
        }
        return loadCol;
    }, [columns]);

    return (
        <s.TableContainer>
            <AntTable<TData>
                {...props}
                dataSource={!loading ? dataSource : (stub as TData[])}
                columns={!loading ? columns : loadColumns}
                pagination={false}
            />
            {pagination && !loading && (
                <s.Pagination
                    pageSize={INIT_ROWS_LENGTH}
                    {...pagination}
                    itemRender={itemRender}
                    showSizeChanger={false}
                    hideOnSinglePage
                />
            )}
        </s.TableContainer>
    );
};

Table.defaultProps = {} as const;

export default Table;
