import styled from '@emotion/styled';

import { mediaBreakpoints } from 'shared/constants';
import { colors } from 'ui';

export const BaseLayout = styled.main`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${colors.bg.gray100};
    overflow: hidden;
`;

export const BaseLayoutContent = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 16px 40px;
    overflow: auto;

    @media only screen and (min-width: ${mediaBreakpoints.tablet}) {
        padding: 10px 40px 40px;
    }
`;
