import { IChipsProps } from './Chips.types';
import * as s from './Chips.styled';
import { colors, TextSmall } from '../Common';

const Chips = ({ children, ...props }: IChipsProps) => {
    return (
        <s.Chips {...props}>
            <TextSmall color={colors.text.whiteMain} bold="medium">
                {children}
            </TextSmall>
        </s.Chips>
    );
};

Chips.defaultProps = {
    color: colors.bg.gray900,
} as const;

export default Chips;
