import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { Button } from 'ui';
import { mainRoute } from 'router';

import NotFoundImg from './NotFound.png';
import * as s from './NotFound.styled';

const NotFound = () => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(mainRoute);
    }, [navigate]);

    return (
        <s.NotFound>
            <s.NotFoundImg src={NotFoundImg} alt="Not Found" />
            <s.NotFoundTitle>Page not found</s.NotFoundTitle>
            <s.NotFoundDescription>
                You may have made a mistake typing the page address <br /> or this page has been
                deleted.
            </s.NotFoundDescription>
            <Button scheme="primary" suffix="arrow_forward" onClick={handleClick}>
                Go to main page
            </Button>
        </s.NotFound>
    );
};

export default NotFound;
