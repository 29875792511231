import { useCallback, useState } from 'react';

const useFormTouched = () => {
    const [isTouched, setTouched] = useState<boolean>(false);

    const handleTouch = useCallback(() => {
        setTouched(true);
    }, []);

    return [isTouched, handleTouch] as const;
};

export default useFormTouched;
