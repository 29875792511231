import * as s from './EmptyResponse.styled';
import NoData from '../assets/images/no-data.svg';
import NotFound from '../assets/images/not-found.svg';
import { IEmptyResponseProps } from './EmptyResponse.types';
import { H5 } from '../Common';

const EmptyResponse = ({ type, message, ...emptyProps }: IEmptyResponseProps) => {
    return (
        <s.EmptyResponse {...emptyProps}>
            <s.NoDataImg src={type === 'empty' ? NoData : NotFound} alt={message} />
            <H5>{message}</H5>
        </s.EmptyResponse>
    );
};

EmptyResponse.defaultProps = {};

export default EmptyResponse;
