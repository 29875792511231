import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

import Button from '../Button';
import Alert from '../Alert';
import PhotoPlaceholderImage from '../assets/images/photo-placeholder.png';
import { IPhotoUploadProps } from './PhotoUpload.types';
import * as s from './PhotoUpload.styled';
import Spin from '../Spin';

const PhotoUpload = ({
    description,
    onChange,
    value,
    loading = false,
    disabled = false,
    maxSize = 3000000,
    accept = 'image/*',
}: IPhotoUploadProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState<string>('');

    const handleAdd = useCallback(() => {
        if (inputRef.current) inputRef.current.click();
    }, []);

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setError('');

            const newFile = e.target.files ? e.target.files[0] : undefined;

            if (!newFile) {
                if (onChange) onChange(undefined);
                return;
            }

            if (newFile.size > maxSize) {
                setError('Invalid image format');
                return;
            }

            if (onChange) onChange(e.target.files ? e.target.files[0] : undefined);
        },
        [onChange, maxSize]
    );

    const handleCancel = useCallback(() => {
        if (onChange) onChange(undefined);
    }, [onChange]);

    const imageSrc = useMemo(() => value ?? PhotoPlaceholderImage, [value]);

    const addBtnText = value ? 'Change photo' : 'Add photo';

    return (
        <s.PhotoUpload>
            <s.PhotoContainer hidden={loading}>
                <s.PhotoImage src={imageSrc} />
                <s.CancelButton hidden={!value} scheme="red" onClick={handleCancel}>
                    delete
                </s.CancelButton>
            </s.PhotoContainer>
            <s.PhotoContainer hidden={!loading}>
                <Spin size="large" />
            </s.PhotoContainer>
            <div>
                <Button onClick={handleAdd} disabled={disabled || loading}>
                    {addBtnText}
                </Button>
                <s.Description>{description}</s.Description>
            </div>
            {error && <Alert type="error">{error}</Alert>}
            <input
                hidden
                type="file"
                ref={inputRef}
                accept={accept}
                multiple={false}
                onChange={handleChange}
            />
        </s.PhotoUpload>
    );
};

PhotoUpload.defaultProps = {
    accept: 'image/*',
    maxSize: 3000000,
    disabled: false,
    loading: false,
};

export default PhotoUpload;
