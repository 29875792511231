import styled from '@emotion/styled';
import { Avatar as AntdAvatar, Skeleton as AntdSkeleton } from 'antd';

import { colors, H3, TextSmall, TextBody } from '../Common';

export const Avatar = styled(AntdAvatar)`
    background: ${colors.bg.gray400};
    border: 0;

    img {
        background: ${colors.bg.grayDisabled};
    }
`;

export const AvatarLoading = styled(AntdSkeleton.Avatar)`
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    border-radius: 50%;
`;

export const AvatarUserSmall = styled(TextSmall)`
    line-height: unset;
    color: ${colors.text.blackTertiary};
`;

export const AvatarUserMedium = styled(TextBody)`
    line-height: unset;
    color: ${colors.text.blackTertiary};
`;

export const AvatarUserLarge = styled(H3)`
    line-height: unset;
    color: ${colors.text.blackTertiary};
`;
