import { css } from '@emotion/react';

import colors from '../colors';

const notificationStyle = css`
    width: auto;
    min-width: 390px;
    max-width: 700px;
    padding: 16px 18px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 3px 6px -4px rgba(0, 0, 0, 0.15),
        0px 6px 16px rgba(0, 0, 0, 0.1);

    .ant-notification-notice-message {
        margin-bottom: 0 !important;
        padding-inline-end: 54px !important;
        margin-inline-start: 30px !important;
    }

    .ant-notification-notice-close {
        top: 16px;
        width: 24px;
        height: 24px;
        inset-inline-end: 16px;
    }
`;

export const notificationStyles = css`
    .notification-success {
        ${notificationStyle};
        background-color: ${colors.bg.green};
    }

    .notification-error {
        ${notificationStyle};
        background-color: ${colors.text.error};
    }

    .notification-warning {
        ${notificationStyle};
        background-color: ${colors.text.warning};
    }
`;
