import { css } from '@emotion/react';

import poppins500 from '../../assets/fonts/poppins-v20-latin-500.woff';
import poppins500_2 from '../../assets/fonts/poppins-v20-latin-500.woff2';
import poppins600 from '../../assets/fonts/poppins-v20-latin-600.woff';
import poppins600_2 from '../../assets/fonts/poppins-v20-latin-600.woff2';
import poppinsRegular from '../../assets/fonts/poppins-v20-latin-regular.woff';
import poppinsRegular_2 from '../../assets/fonts/poppins-v20-latin-regular.woff2';
import icons from '../../assets/fonts/icons.woff2';

export const fontsStyles = css`
    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        src: local(''), url('${poppinsRegular_2}') format('woff2'),
            url('${poppinsRegular}') format('woff');
    }

    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        src: local(''), url('${poppins500_2}') format('woff2'), url('${poppins500}') format('woff');
    }

    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        src: local(''), url('${poppins600_2}') format('woff2'), url('${poppins600}') format('woff');
    }

    @font-face {
        font-family: 'Material Symbols Rounded';
        font-style: normal;
        font-display: block;
        font-weight: 200 400;
        src: url(${icons}) format('woff2');
    }

    .material-symbols-rounded {
        font-family: 'Material Symbols Rounded', sans-serif;
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }

    body {
        font-family: 'Poppins', sans-serif;
    }
`;
