import { useState } from 'react';

import { IPhotoGalleryProps, TPhoto } from './PhotoGallery.types';
import * as s from './PhotoGallery.styled';
import Icon from '../Icon';

const PhotoGallery = ({ photos }: IPhotoGalleryProps) => {
    const [activePhoto, setActivePhoto] = useState<TPhoto>(photos[0]);

    const setNextPhoto = () => {
        if (photos.indexOf(activePhoto) === photos.length - 1) {
            setActivePhoto(photos[0]);
        } else {
            setActivePhoto(photos[photos.indexOf(activePhoto) + 1]);
        }
    };

    const setPrevPhoto = () => {
        if (photos.indexOf(activePhoto) === 0) {
            setActivePhoto(photos[photos.length - 1]);
        } else {
            setActivePhoto(photos[photos.indexOf(activePhoto) - 1]);
        }
    };

    return (
        <s.PhotoGalleryContainer>
            <s.MainPhotoContainer>
                <s.NavItems>
                    <Icon scheme="secondary" onClick={setPrevPhoto}>
                        arrow_back
                    </Icon>
                    <Icon scheme="secondary" onClick={setNextPhoto}>
                        arrow_forward
                    </Icon>
                </s.NavItems>
                <s.Photo src={activePhoto.link} />
            </s.MainPhotoContainer>
            <s.PhotosContainer>
                {photos.map((photo) => (
                    <s.PhotoPrev
                        src={photo.link}
                        onClick={() => setActivePhoto(photo)}
                        isActive={photo.id === activePhoto.id}
                    />
                ))}
            </s.PhotosContainer>
        </s.PhotoGalleryContainer>
    );
};

PhotoGallery.defaultProps = {};

export default PhotoGallery;
