import styled from '@emotion/styled';

import { colors, TextSmall } from '../Common';
import Icon from '../Icon';

export const PhotoUpload = styled.div`
    display: grid;
    grid-template-columns: 130px auto;
    align-items: center;
    gap: 20px 30px;

    em:last-of-type {
        grid-column: 2 span;
    }
`;

export const PhotoContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 130px;
    height: 130px;
    border-radius: 8px;
    background: ${colors.bg.gray200};

    &:hover > button {
        opacity: 1;
    }
`;

export const PhotoImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
`;

export const Description = styled(TextSmall)`
    color: ${colors.text.blackTertiary};
    margin-top: 12px;
`;

export const CancelButton = styled(Icon)`
    opacity: 0;
    position: absolute;
    height: 36px;
    width: 36px;
    top: -10px;
    right: -14px;

    transition: opacity ease-out 0.2s;
`;
