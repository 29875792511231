import React from 'react';

import { ITabsProps } from './Tabs.types';
import * as s from './Tabs.styled';

export const Tabs = ({ items, defaultActiveKey, onChange, ...props }: ITabsProps) => {
    return (
        <s.Tabs items={items} defaultActiveKey={defaultActiveKey} onChange={onChange} {...props} />
    );
};

Tabs.defaultProps = {
    color: 'inherit',
} as const;
