import { ISegmentedProps } from './Segmented.types';
import * as s from './Segmented.styled';

const Segmented = ({ options, value, onChange, size, block }: ISegmentedProps) => {
    return (
        <s.Segmented
            block={block}
            options={options}
            value={value}
            onChange={onChange}
            size={size}
        />
    );
};

Segmented.defaultProps = {};

export default Segmented;
