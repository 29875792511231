export const download = (fileName: string, data: Blob) => {
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
};
