import { TextBody } from '../Common';
import { ILinkProps } from './Link.types';
import * as s from './Link.styled';

const Link = ({ prefix, suffix, children, ...linkProps }: ILinkProps) => {
    return (
        <s.Link {...linkProps}>
            <s.LinkIcon hidden={!prefix}>{prefix}</s.LinkIcon>
            <TextBody>{children}</TextBody>
            <s.LinkIcon hidden={!suffix}>{suffix}</s.LinkIcon>
        </s.Link>
    );
};

Link.defaultProps = {
    disabled: false,
} as const;

export default Link;
