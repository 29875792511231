import { LazyExoticComponent } from 'react';

import { TPermission } from 'services/Permissions';

export enum Routes {
    MAIN = '/',
    DIARY_POSTS = '/diary',
    DIARY_POST = '/diary/:id',
    CREATE_DIARY_POST = '/diary/new',
    EDIT_DIARY_POST = '/diary/edit/:id',
    PROFILE = '/profile',
    SETTINGS = '/settings',
    PROFILE_ATTACHMENTS = '/profile/attachments',
    PRACTITIONER = '/practitioner/:id',
    SUPPORT = '/support',
    LOGS = '/logs',
    TREATMENT = '/treatment',
}

export const mainRoute = Routes.PROFILE;

export type TRoute = {
    path: Routes;
    Component: LazyExoticComponent<() => JSX.Element>;
    permission?: TPermission;
    children?: TRoute[];
};
