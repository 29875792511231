import { useKeycloak } from '@react-keycloak/web';

import { getFullName } from '../utils';

// TODO: change it
const PHOTO_URL = 'api/profile/photo';

const useMenuProfile = () => {
    const { keycloak } = useKeycloak();

    const keycloakUser = keycloak.tokenParsed;

    const firstName = keycloakUser?.given_name || '';

    const lastName = keycloakUser?.family_name || '';

    return {
        photoUrl: PHOTO_URL,
        name: getFullName({ firstName, lastName }),
    };
};

export default useMenuProfile;
