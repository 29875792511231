import styled from '@emotion/styled';

import { colors } from '../Common';
import MaterialIcon from '../MaterialIcon';

export const ButtonLink = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0;

    width: fit-content;

    color: ${colors.text.accent};
    text-decoration: none;
    background-color: transparent;
    border: none;

    &:focus {
        cursor: pointer;
        color: ${colors.text.accentDarker};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover {
        cursor: pointer;
        color: ${colors.text.accentDarker};
    }

    &[disabled] {
        pointer-events: none;
        color: ${colors.text.blackDisabled};
    }
`;

export const ButtonLinkIcon = styled(MaterialIcon)`
    display: inherit;
    font-size: 16px;
    font-weight: 300;
`;
