import { useCallback } from 'react';

import { TextBody, TextSmall } from '../../Common';
import { ISidebarItemProps } from '../Sidebar.types';
import * as s from '../Sidebar.styled';

export const SidebarItem = ({ item, onSelect, selected, ...props }: ISidebarItemProps) => {
    const handleClick = useCallback(() => {
        if (!selected && item.path) onSelect(item.path);
        if (item.onClick) item.onClick();
    }, [onSelect, item, selected]);

    return (
        <s.SidebarItem aria-current={selected} onClick={handleClick} {...props}>
            <s.SidebarItemIcon badge={item.badge}>{item.icon}</s.SidebarItemIcon>
            <TextSmall>{item.text}</TextSmall>
        </s.SidebarItem>
    );
};

export const SidebarSubItem = ({ item, onSelect, selected, ...props }: ISidebarItemProps) => {
    const handleClick = useCallback(() => {
        if (!selected && item.path) onSelect(item.path);
        if (item.onClick) item.onClick();
    }, [onSelect, item, selected]);

    return (
        <s.SidebarSubItem aria-current={selected} onClick={handleClick} {...props}>
            <s.SidebarSubItemIcon badge={item.badge}>{item.icon}</s.SidebarSubItemIcon>
            <TextBody>{item.text}</TextBody>
        </s.SidebarSubItem>
    );
};
