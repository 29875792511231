const ACCESS_TOKEN_KEY = 'access';

const StorageService = {
    get accessToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
    },
    set accessToken(value: string | undefined) {
        if (value) localStorage.setItem(ACCESS_TOKEN_KEY, value);
        else localStorage.removeItem(ACCESS_TOKEN_KEY);
    },
};

export default StorageService;
