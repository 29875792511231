import { Form, Select as AntdSelect, Select } from 'antd';

import { IInputWithSelectProps } from './InputWithSelect.types';
import * as s from './InputWithSelect.styled';
import { colors } from '../Common';

const selectIcon = <s.SelectIcon>done</s.SelectIcon>;

const InputWithSelect = ({
    label,
    allowClear,
    options,
    placeholder,
    required,
    selectorName,
    selectLoading,
    isSelectAfter = false,
    selectDefaultValue,
    ...inputProps
}: IInputWithSelectProps) => {
    const prefixSelector = (
        <Form.Item name={selectorName} noStyle>
            <Select
                disabled={inputProps.disabled}
                menuItemSelectedIcon={selectIcon}
                loading={selectLoading}
                defaultValue={selectDefaultValue}
            >
                {options?.map(({ value, label: optionLabel }) => (
                    <AntdSelect.Option key={value} value={value}>
                        <s.Option>{optionLabel}</s.Option>
                    </AntdSelect.Option>
                ))}
            </Select>
        </Form.Item>
    );

    return (
        <label>
            <s.LabelText color={colors.text.blackSecondary} hidden={!label}>
                {label} {required && '*'}
            </s.LabelText>
            {options?.length && (
                <s.InputContainer
                    addonBefore={!isSelectAfter && prefixSelector}
                    addonAfter={isSelectAfter && prefixSelector}
                    placeholder={placeholder}
                    allowClear
                    {...inputProps}
                />
            )}
        </label>
    );
};

InputWithSelect.defaultProps = {
    allowClear: true,
    disabled: false,
    required: false,
} as const;

export default InputWithSelect;
