import styled from '@emotion/styled';

import { colors } from '../Common';
import { ICheckboxProps } from './Checkbox.types';

export const CheckboxLabel = styled.label`
    position: relative;
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 26px;
    cursor: pointer;
    color: ${colors.text.blackMain};

    &[aria-disabled='true'] {
        cursor: auto;
        color: ${colors.text.blackDisabled};
    }
`;

export const Checkbox = styled.input`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked + span {
        border-color: transparent;
        background-color: ${colors.text.blackMain};

        &:after {
            display: block;
        }
    }

    &:disabled + span {
        border-color: ${colors.text.blackDisabled};
    }

    &:checked:disabled + span {
        background-color: ${colors.text.blackDisabled};
    }

    &:focus + span {
        border-color: ${colors.bg.accent};
        box-shadow: 0 0 0 2px ${colors.border.focus};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover + span {
        border-color: ${colors.text.blackMain};
    }
`;

export const Checkmark = styled.span<Pick<ICheckboxProps, 'checked'>>`
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: ${colors.bg.main};
    border: 1px solid ${colors.border.main};
    border-radius: 2px;
    transition: background-color ease-out 0.2s, border ease-out 0.2s;

    &:after {
        display: none;
        left: 5px;
        top: 2px;
        width: 6.5px;
        height: 8.5px;
        border: solid white;
        border-width: 0 2.3px 2.3px 0;
        transform: rotate(45deg);
        content: '';
        position: absolute;
    }
`;
