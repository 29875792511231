import { useMemo } from 'react';

import { H3 } from '../Common';
import Button from '../Button';
import { IModalProps } from './Modal.types';
import * as s from './Modal.styled';

const Modal = ({
    onOk,
    onCancel,
    title,
    children,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
    ...modalProps
}: IModalProps) => {
    const footerComponent = useMemo(() => {
        return (
            <s.ModalFooter>
                <Button onClick={onCancel} {...cancelButtonProps}>
                    {cancelText}
                </Button>
                <Button scheme="primary" onClick={onOk} {...okButtonProps}>
                    {okText}
                </Button>
            </s.ModalFooter>
        );
    }, [cancelText, okText, okButtonProps, cancelButtonProps, onCancel, onOk]);

    const titleComponent = useMemo(() => {
        return <H3 bold="medium">{title}</H3>;
    }, [title]);

    return (
        <s.Modal
            {...modalProps}
            onCancel={onCancel}
            title={titleComponent}
            footer={modalProps.footer || footerComponent}
            closeIcon={<s.CloseIcon>close</s.CloseIcon>}
        >
            <s.ModalContent>{children}</s.ModalContent>
        </s.Modal>
    );
};

Modal.defaultProps = {
    okText: 'Next',
    cancelText: 'Cancel',
    open: false,
    centered: true,
    closable: true,
    destroyOnClose: true,
    maskClosable: false,
} as const;

export default Modal;
