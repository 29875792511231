import { notification as antdNotification } from 'antd';

import * as s from './Notification.styled';

const notification: typeof antdNotification = {
    ...antdNotification,
    success: (args) => {
        antdNotification.success({
            ...args,
            className: 'notification-success',
            message: <s.Message>{args.message}</s.Message>,
            icon: <s.NotificationIcon>check_circle</s.NotificationIcon>,
        });
    },
    error: (args) => {
        antdNotification.error({
            ...args,
            className: 'notification-error',
            message: <s.Message>{args.message}</s.Message>,
            icon: <s.NotificationIcon>cancel</s.NotificationIcon>,
        });
    },
    warning: (args) => {
        antdNotification.warning({
            ...args,
            className: 'notification-warning',
            message: <s.Message>{args.message}</s.Message>,
            icon: <s.NotificationIcon>error</s.NotificationIcon>,
        });
    },
};

notification.config({
    duration: 5,
    closeIcon: <s.CloseIcon>close</s.CloseIcon>,
});

export default notification;
