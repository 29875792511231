import AccessDeniedImg from './AccessDenied.png';
import * as s from './AccessDenied.styled';

const AccessDenied = () => {
    return (
        <s.AccessDenied>
            <s.AccessDeniedImg src={AccessDeniedImg} alt="Access Denied" />
            <s.AccessDeniedTitle>Access Denied</s.AccessDeniedTitle>
            <s.AccessDeniedDescription>
                Your role doesn’t have permission to access this page. <br />
                Contact your Wellacy admin to get access.
            </s.AccessDeniedDescription>
        </s.AccessDenied>
    );
};

export default AccessDenied;
