import styled from '@emotion/styled';

import { colors } from '../Common';
import { IButtonAddProps } from './ButtonAdd.types';

export const ButtonAdd = styled.button<IButtonAddProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 80px;

    color: ${colors.text.accent};
    background-color: transparent;
    border: 1px dashed ${colors.border.main};
    border-radius: 16px;

    &:hover {
        background-color: ${colors.bg.mainHover};
    }

    &:disabled {
        background-color: transparent;
        color: ${colors.text.blackDisabled};
    }

    &:focus {
        cursor: pointer;
        border: 1px solid ${colors.bg.accent};
        box-shadow: 0 0 0 2px ${colors.border.focus};
        background-color: ${colors.bg.gray100};
        outline-color: transparent;
        outline-width: 0;
    }

    &:not(:disabled):not(:focus):hover {
        cursor: pointer;
    }
`;

export const ButtonAddContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;
