export const reactions = [
    {
        label: 'HEART',
        symbol: '❤️',
    },
    {
        label: 'SMILEY',
        symbol: '😃',
    },
    {
        label: 'HEART_EYES',
        symbol: '😍',
    },
    {
        label: 'MUSCLE',
        symbol: '💪',
    },
    {
        label: 'HUSHED',
        symbol: '😯',
    },
    {
        label: 'FROWNING',
        symbol: '🙁',
    },
];
