import { ChangeEvent, useCallback } from 'react';

import { H5 } from '../Common';
import Checkbox from '../Checkbox';
import { ICheckboxGroupItemProps, ICheckboxGroupProps } from './CheckboxGroup.types';
import * as s from './CheckboxGroup.styled';

const CheckboxGroupItem = ({
    label,
    value,
    checked,
    children,
    onChange,
}: ICheckboxGroupItemProps) => {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange(value, event.target.checked);
        },
        [onChange, value]
    );

    return (
        <div>
            <Checkbox checked={checked} onChange={handleChange}>
                {label}
            </Checkbox>
            {children && checked && <s.CheckboxChildren>{children}</s.CheckboxChildren>}
        </div>
    );
};

const CheckboxGroup = ({
    onChange,
    label,
    options,
    value = [],
    layout = 'vertical',
}: ICheckboxGroupProps) => {
    const handleChange = useCallback(
        (newValue: string, checked: boolean) => {
            if (!onChange) return;

            if (checked) onChange([...value, newValue]);
            else onChange(value.filter((valName) => valName !== newValue));
        },
        [onChange, value]
    );

    return (
        <s.CheckboxGroup>
            {label && <H5 bold="medium">{label}</H5>}
            <s.CheckboxGroupItems layout={layout}>
                {options.map((option) => (
                    <CheckboxGroupItem
                        key={option.value}
                        onChange={handleChange}
                        checked={value.includes(option.value)}
                        {...option}
                    />
                ))}
            </s.CheckboxGroupItems>
        </s.CheckboxGroup>
    );
};

CheckboxGroup.defaultProps = {
    value: [],
    layout: 'vertical',
} as const;

export default CheckboxGroup;
