import styled from '@emotion/styled';
import { css } from '@emotion/react';

type TTypographyBold = 'semibold' | 'medium';

type TTypographyProps = {
    color?: string;
    bold?: TTypographyBold;
};

const getWeight = (bold?: TTypographyBold) => {
    switch (bold) {
        case 'semibold':
            return 600;
        case 'medium':
            return 500;
        default:
            return 400;
    }
};

const TypographyElement = ({ color, bold }: TTypographyProps) => css`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: ${getWeight(bold)};
    color: ${color ?? 'inherit'};
    margin: 0;
`;

export const H1Big = styled.h1<TTypographyProps>`
    ${TypographyElement};
    font-size: 40px;
    line-height: 60px;
`;

export const H1Small = styled.h1<TTypographyProps>`
    ${TypographyElement};
    font-size: 32px;
    line-height: 48px;
`;

export const H3 = styled.h3<TTypographyProps>`
    ${TypographyElement};
    font-size: 20px;
    line-height: 28px;
`;

export const H4 = styled.h4<TTypographyProps>`
    ${TypographyElement};
    font-size: 20px;
    line-height: 28px;
`;

export const H5 = styled.h5<TTypographyProps>`
    ${TypographyElement};
    font-size: 16px;
    line-height: 24px;
`;

export const TextBody = styled.p<TTypographyProps>`
    ${TypographyElement};
    font-size: 14px;
    line-height: 22px;
`;

export const TextSmall = styled.p<TTypographyProps>`
    ${TypographyElement};
    font-size: 12px;
    line-height: 20px;
`;
