import styled from '@emotion/styled';

import { IChipsProps } from './Chips.types';

export const Chips = styled.div<Pick<IChipsProps, 'color'>>`
    height: 24px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 6px;
    background: ${({ color }) => color};
`;
