import { useCallback, useState } from 'react';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib';

import Icon from '../Icon';
import Spin from '../Spin';
import { colors, TextBody, TextSmall } from '../Common';
import { IDraggerProps, IUploadItemProps } from './Dragger.types';
import * as s from './Dragger.styled';

const bytesToMB = (bytes: number) => (bytes / 1024 / 1024).toFixed(4);

const UploadItem = ({ file, onRemove }: IUploadItemProps) => {
    const handleRemove = useCallback(() => {
        if (onRemove) onRemove(file);
    }, [onRemove, file]);

    const isError = file.status === 'error';
    const isDone = file.status === 'done';
    const isUploading = file.status === 'uploading';

    const fileSize = file.size ? bytesToMB(file.size) : 0;

    return (
        <s.UploadItem>
            <s.UploadItemInfo>
                <s.UploadingIcon hidden={!isUploading}>
                    <Spin size="small" color={colors.bg.main} />
                </s.UploadingIcon>
                {isError && <s.ErrorIcon fill>cancel</s.ErrorIcon>}
                {isDone && <s.SuccessIcon fill>check_circle</s.SuccessIcon>}
                <s.FileName bold="medium" color={colors.text.blackMain}>
                    {file.name}
                </s.FileName>
                <s.FileSize color={colors.text.blackTertiary}>{fileSize} MB</s.FileSize>
                <Icon scheme="inline" onClick={handleRemove}>
                    delete
                </Icon>
            </s.UploadItemInfo>
            <s.UploadItemError hidden={!isError}>
                <s.FileError>Something went wrong and the file didn&apos;t load</s.FileError>
            </s.UploadItemError>
        </s.UploadItem>
    );
};

const Dragger = ({
    description,
    fileList = [],
    onChange,
    onRemove,
    ...draggerProps
}: IDraggerProps) => {
    const [isDragOver, setDrugOver] = useState(false);

    const handleDragEnter = useCallback(() => {
        setDrugOver(true);
    }, []);

    const handleDragEnd = useCallback(() => {
        setDrugOver(false);
    }, []);

    const handleChange = useCallback(
        (event: UploadChangeParam<UploadFile>) => {
            if (onChange) onChange([...event.fileList]);
            setDrugOver(false);
        },
        [onChange]
    );

    const handleRemove = useCallback(
        (file: UploadFile) => {
            const newFileList = fileList.filter(({ uid }) => uid !== file.uid);

            if (onChange) onChange([...newFileList]);
            if (onRemove) onRemove(file);
        },
        [onChange, onRemove, fileList]
    );

    return (
        <div>
            <s.Dragger
                {...draggerProps}
                fileList={fileList}
                onChange={handleChange}
                showUploadList={false}
            >
                <div
                    onDrop={handleDragEnd}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragEnd}
                >
                    {isDragOver && (
                        <s.DraggerOverContent>
                            <TextBody color={colors.text.accent}>Drag documents here</TextBody>
                        </s.DraggerOverContent>
                    )}
                    {!isDragOver && (
                        <s.DraggerContent>
                            <TextBody color={colors.text.blackSecondary}>
                                Drag and drop documents, or{' '}
                                <s.DraggerAccent>Browse</s.DraggerAccent>
                            </TextBody>
                            {description && (
                                <TextSmall color={colors.text.blackTertiary}>
                                    {description}
                                </TextSmall>
                            )}
                        </s.DraggerContent>
                    )}
                </div>
            </s.Dragger>
            <s.UploadItems hidden={fileList.length === 0}>
                {fileList.map((file) => {
                    return <UploadItem key={file.uid} file={file} onRemove={handleRemove} />;
                })}
            </s.UploadItems>
        </div>
    );
};

Dragger.defaultProps = {
    multiple: true,
    maxCount: 10,
    accept: 'application/pdf',
    fileList: [],
};

export default Dragger;
